<template>
  <v-app>
    <v-container>
      <!-- LCP -->
      <v-row>
        <v-col>
          <v-card>
            <v-row>
              <v-col class="col-3">
                <img
                  :src="require('@/assets/images/lcp_ux.svg')"
                  class="large_logo"
              /></v-col>
              <v-col class="col-9">
                <v-card-title>
                  <h5>Largest Contentful Paint (LCP) for {{ sitetitle }}</h5>
                </v-card-title>
                <v-card-subtitle
                  >Largest Contentful Paint (LCP) is an important, user-centric
                  metric for measuring perceived load speed because it marks the
                  point in the page load timeline when the page's main content
                  has likely loaded—a fast LCP helps reassure the user that the
                  page is useful.
                  <br />
                  <a href="https://web.dev/lcp/" target="blank"> more info </a>
                  <v-icon x-small color="darken-2">mdi-open-in-new</v-icon>
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-text>
              <graphPagespeedInsightsLCP
                title="LCP"
                v-bind:siteurl="this.$props.siteurl"
                v-bind:siteid="this.$props.siteid"
                v-bind:sitetitle="this.$props.sitetitle"
                v-bind:passeddata="graphData"
              >
              </graphPagespeedInsightsLCP>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- FID -->
      <v-row>
        <v-col>
          <v-card>
            <v-row>
              <v-col class="col-3">
                <img
                  :src="require('@/assets/images/fid_ux.svg')"
                  class="large_logo"
              /></v-col>
              <v-col>
                <v-card-title>
                  <h5>First Input Delay (FID) for {{ sitetitle }}</h5>
                </v-card-title>
                <v-card-subtitle
                  >First Input Delay (FID) is an important, user-centric metric
                  for measuring load responsiveness because it quantifies the
                  experience users feel when trying to interact with
                  unresponsive pages—a low FID helps ensure that the page is
                  usable.
                  <br />
                  <a href="https://web.dev/fid/" target="blank"> more info </a>
                  <v-icon x-small color="darken-2">mdi-open-in-new</v-icon>
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <graphPagespeedInsightsFID
              title="FID"
              v-bind:passedurl="this.$props.siteurl"
              v-bind:passedsiteid="this.$props.siteid"
              v-bind:passeddata="graphData"
            ></graphPagespeedInsightsFID>
            <v-card-text> </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- CLS-->
      <v-row>
        <v-col>
          <v-card>
            <v-row>
              <v-col class="col-3">
                <img
                  :src="require('@/assets/images/cls_ux.svg')"
                  class="large_logo"
              /></v-col>
              <v-col>
                <v-card-title>
                  <h5>Cumulative Layout Shirt(CLS) for {{ sitetitle }}</h5>
                </v-card-title>
                <v-card-subtitle
                  >Cumulative Layout Shift (CLS) is an important, user-centric
                  metric for measuring visual stability because it helps
                  quantify how often users experience unexpected layout shifts—a
                  low CLS helps ensure that the page is delightful.
                  <br />
                  <a href="https://web.dev/cls/" target="blank"> more info </a>
                  <v-icon x-small color="darken-2">mdi-open-in-new</v-icon>
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-divider> </v-divider>
            <graphPagespeedInsightsCLS
              title="CLS"
              v-bind:passedurl="this.$props.siteurl"
              v-bind:passedsiteid="this.$props.siteid"
              v-bind:passeddata="graphData"
            ></graphPagespeedInsightsCLS>
            <GraphLighthousepagescore
              title="Pagescore"
              v-bind:passedurl="this.$props.siteurl"
              v-bind:passedsiteid="this.$props.siteid"
              v-bind:passeddata="graphData"
            ></GraphLighthousepagescore>
            <v-card-text> </v-card-text>
          </v-card>
          <!--  {{graphData}}-->
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { fc, db } from "@/firebase/init";
//import db from "@/firebase/init";
import moment from "moment";
import graphPagespeedInsightsLCP from "@/components/site_items_components/graphs/graphPagespeedInsightsLCP";
import graphPagespeedInsightsFID from "@/components/site_items_components/graphs/graphPagespeedInsightsFID";
import graphPagespeedInsightsCLS from "@/components/site_items_components/graphs/graphPagespeedInsightsCLS";
import GraphLighthousepagescore from "@/components/site_items_components/graphs/graphLighthousepagescore";
export default {
  name: "PagespeedInsights",
  props: ["siteid", "sitetitle", "siteurl"],
  data() {
    return {
      graphData: [],
    };
  },
  components: {
    graphPagespeedInsightsLCP,
    graphPagespeedInsightsFID,
    graphPagespeedInsightsCLS,
    GraphLighthousepagescore,
  },
  methods: {
    readData() {
      let loadplatform = null;
      let sid = this.$props.siteid;

      let platform = this.$store.state.platform;
     // alert(platform);

      if (platform == "mobile") {
        loadplatform = "m_pagespeed";
      } else {
        loadplatform = "pagespeed";
      }
     // alert(loadplatform);

      this.graphData = [];
      db.collection("Sites")
        .doc(sid)
        .collection(loadplatform)
        .orderBy("date", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.graphData.push({
              date: moment.unix(doc.data().date.seconds).valueOf(),
              CLSGood: doc.data().loadingExperience[0].CLS_Good,
              CLSMed: doc.data().loadingExperience[0].CLS_Med,
              CLSPoor: doc.data().loadingExperience[0].CLS_Poor,
              FIDGood: doc.data().loadingExperience[0].FID_Good,
              FIDMed: doc.data().loadingExperience[0].FID_Med,
              FIDPoor: doc.data().loadingExperience[0].FID_Poor,
              LCPGood: doc.data().loadingExperience[0].LCP_Good,
              LCPMed: doc.data().loadingExperience[0].LCP_Med,
              LCPPoor: doc.data().loadingExperience[0].LCP_Poor,
              Pagescore: doc.data().lighthouse[0].PerformanceScore,
            });
            //  console.log(doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          //  console.log("Error getting documents: ", error);
        });
    },
  },
  mounted() {
    this.readData();
  },
};
</script>
<style>
.small_logo {
  height: 50px;
}
.large_logo {
  height: 200px;
}
</style>
