<template>
  <v-container>
    <v-row>
      <v-col col="1" style="margin-left:-25px">
        <v-breadcrumbs :items="items">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row
      ><v-col md="6"><h1>Sites</h1></v-col>
      <v-col md="6">
        <router-link to="/add-site">
          <v-btn 
      
      color="blue-grey darken-3" dark class="float-right me-3"
            ><v-icon dark left> mdi-earth </v-icon>Add site</v-btn
          >
        </router-link>
      </v-col>
    </v-row>
    <v-row v-if="this.$store.state.platform === 'desktop'">
      <v-col ><Sites /></v-col
    ></v-row>
     <v-row v-if="this.$store.state.platform === 'mobile'">
      <v-col ><Sites_m /></v-col
    ></v-row>
  </v-container>
</template>

<script>
import Sites from "@/components/Sites_component";
import Sites_m from "@/components/Sites_component_m";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "dashboard",
      },
      {
        text: "Sites",
        disabled: false,
        href: "sites",
      },
    ],
  }),
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    Sites,
    Sites_m,
    // Sites_table,
    // Sites_select
  },
};
</script>

<style>
.add_site {
  margin-top: 50px;
}

.refreshtext {
  font-size: 10px;
}
.refresh:link,
.refresh:visited {
  background: orange;
  text-decoration: none;
  padding: 0px 5px;
  font-size: 10px;
  border-radius: 5px;
  color: white;
  margin-right: 2px;
}
.refresh:hover {
  background: black;
}

.test {
  color: orange;
}
</style>
