var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.computedHeaders,"items":_vm.sites,"items-per-page":30,"item-key":"sites","sort-by":['title'],"sort-desc":[false]},scopedSlots:_vm._u([{key:"item.featured",fn:function(ref){
var item = ref.item;
return [(item.featured === 'true')?[_c('v-icon',{attrs:{"medium":"","color":"orange darken-5"}},[_vm._v("mdi-star")])]:_vm._e()]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Site', params: { slug: item.slug } }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.Scannedpage",fn:function(ref){
var item = ref.item;
return [(
            item.website + '/' == item.Scannedpage ||
              item.website == item.Scannedpage
          )?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-emoticon")])]}}],null,true)},[_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-emoticon")]),_vm._v("Getting Page Results - use both "),_c('br'),_vm._v("Pagespeed = "+_vm._s(item.Scannedpage)),_c('br'),_vm._v(" Site - "+_vm._s(item.website + "/"))],1)])],1):_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v("mdi-emoticon-frown")])]}}],null,true)},[_c('span',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-emoticon-frown")]),_vm._v("Getting Site Origins - use lighthouse only "),_c('br'),_vm._v("Pagespeed = "+_vm._s(item.Scannedpage)),_c('br'),_vm._v(" Site - "+_vm._s(item.website + "/"))],1)])],1)]}},{key:"item.LH_score",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorlh_score(item.LH_score),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.LH_score)+" ")])]}},{key:"item.LH_speedindex",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_speedindex(item.LH_speedindex),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.LH_speedindex)+"s ")])]}},{key:"item.LH_timetointeractive",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_timetointeractive(item.LH_timetointeractive),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.LH_timetointeractive)+"s ")])]}},{key:"item.LH_fcp",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_fcp(_vm.LH_fcp),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.LH_fcp)+"s ")])]}},{key:"item.LH_tbt",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_tbt(item.LH_tbt),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.LH_tbt)+"ms ")])]}},{key:"item.LH_cls",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_cls(item.LH_cls),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.LH_cls)+" ")])]}},{key:"item.LH_lcp",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_lcp(item.LH_lcp),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.LH_lcp)+"s ")])]}},{key:"item.PS_FCP_Percentile",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{class:item.PS_FCP,attrs:{"x-small":"","label":""}},[_vm._v(" "+_vm._s(item.PS_FCP_Percentile)+" s ")])]}},{key:"item.PS_FID_Percentile",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{class:item.PS_FID,attrs:{"label":"","x-small":""}},[_vm._v(" "+_vm._s(item.PS_FID_Percentile)+" ms ")])]}},{key:"item.PS_LCP_Percentile",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{class:item.PS_LCP,attrs:{"label":"","x-small":""}},[_vm._v(" "+_vm._s(item.PS_LCP_Percentile)+" s ")])]}},{key:"item.PS_CLS_Percentile",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{class:item.PS_CLS,attrs:{"x-small":"","label":""}},[_vm._v(" "+_vm._s(item.PS_CLS_Percentile)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'EditSite', params: { slug: item.slug } }}},[_c('v-icon',{staticClass:"mr-2 edit",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}},{key:"top",fn:function(){return [_c('v-app-bar',{attrs:{"flat":"","dense":"","color":"white"}},[_c('v-toolbar-title',[(!_vm.switch1)?_c('h5',[_vm._v("Page Speed Insites")]):_vm._e(),(_vm.switch1)?_c('h5',[_vm._v(" Lighthouse ")]):_vm._e()]),_c('v-spacer'),(_vm.switch1)?_c('v-icon',{attrs:{"mediumv":"","color":"primary"}},[_vm._v("mdi-lighthouse")]):_vm._e(),(!_vm.switch1)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lighthouse")]):_vm._e(),_c('v-switch',{staticStyle:{"margin-top":"20px"},model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}})],1)]},proxy:true}])}),_vm._v(" "+_vm._s(_vm.item)+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }