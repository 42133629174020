<template>
  <!-- This component pulls any med / low lighthouse sites and orders by slowest on top-->
  <div>
    <!-- {{ sites }}-->
    <v-data-table
      dense
      :headers="computedHeaders"
      :items="sites"
      :items-per-page="40"
      item-key="sites"
      hide-default-footer
      hide-default-header
      class="table_grey"

      
    >
      <!-- Score column-->
      <template v-slot:item.LH_score="{ item }">
        <span :class="getColorlh_score(item.LH_score)" class="round">
          {{ item.LH_score }}
        </span>
      </template>
      <!-- Title column-->
      <template v-slot:item.title="{ item }">
        <router-link :to="{ name: 'Site', params: { slug: item.slug } }">
          <span class="smalltitle">{{ item.title }}</span>
        </router-link>
      </template>
      <!-- Lastupdated-->
      <template v-slot:item.date="{ item }">
        <span class="smalldate">{{ item.date }}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import * as firebase from "firebase";
import { fc, db } from "@/firebase/init";
import moment from "moment";
export default {
  name: "Sites_dashboard_issues",
  data() {
    return {
      search: "",
      sites: [],
    };
  },
  computed: {
    computedHeaders() {
      let headers = [
        { text: "S", value: "LH_score" },
        { text: "Title", value: "title" },
        { text: "Updated", value: "date" },
         { text: "Unit", value: "unit" },
      ];
      return headers;
    },
  },
  methods: {
    //start traffic lights
    getColorlh_score(lh) {
      if (lh < 49) return "red";
      else if (lh < 89) return "orange";
      else return "green";
    },
  },
  watch: {},
  created() {
    // fetch data from firestore
    db.collection("Sites")
     //.where("LH_score", "<=", 60)
     //.where("unit", "==", "Ireland")
     .orderBy("LH_score")
     .limit(20)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());
          let site = doc.data();
          site.date = moment.unix(site.date.seconds).format("DD/MM/YY");
          site.id = doc.id;
          this.sites.push(site);
          
        });
      });
  },
};
</script>
<style>
.AVERAGE {
  background: orange !important;
  color: white !important;
}
.SLOW {
  background: RED !important;
  color: white !important;
}
.FAST {
  background: green !important;
  color: white !important;
}
.round {
  border-radius: 15px;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;

  color: white;
}
.v-chip .v-chip__content {
  min-width: 30px !important;
  text-align: center !important;
}
.link:link {
  text-decoration: none;
}
.link:link .edit,
.link:visited .edit {
  color: rgba(0, 0, 0, 0.2);
}
.link:hover .edit {
  color: orange;
}
.smalldate {
  color: grey;
  font-size: 12px !important;
}
a:link .smalltitle,
a:visited.smalltitle {
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}
.h5header {
  color: red;
}

.table_grey {
  background-color: #eeeeee !important;
}
</style>
