<template>
  <div>
    <!--  {{sites}} -->
    <v-row>
      <v-col
        ><v-switch
          v-model="switch1"
          class="float-right"
          :label="`Lighthouse`"
          inset
          small
        ></v-switch> </v-col
    ></v-row>

    <v-card>
      <v-data-table
        dense
        :headers="computedHeaders"
        :items="sites"
        :items-per-page="30"
        item-key="sites"
        :sort-by="['title']"
        :sort-desc="[false]"
        :search="search"
      >
        <!-- featured column ok-->
        <template v-slot:item.featured="{ item }">
          <template v-if="item.featured === 'true'"
            ><v-icon medium color="orange darken-5">mdi-star</v-icon></template
          >
        </template>
        <template v-slot:item.date="{ item }">
          <span class="smalldate">{{ item.date }}</span>
        </template>
        <!-- Title column-->
        <template v-slot:item.title="{ item }">
          <router-link :to="{ name: 'Site', params: { slug: item.slug } }">
            {{ item.title }}
          </router-link>
        </template>
        <!-- lighthouse -->
        <!-- score -->
        <template v-slot:item.LH_score="{ item }">
          <v-avatar
            :color="getColorlh_score(item.LH_score)"
            dark
            small
            size="25"
            class="avatar"
          >
            {{ item.LH_score }}
          </v-avatar>
        </template>
        <!-- score -->
        <!-- Lighthouse Speed Index OK -->
        <template v-slot:item.LH_speedindex="{ item }">
          <v-chip :color="getColorLH_speedindex(item.LH_speedindex)" dark small>
            {{ item.LH_speedindex }}s
          </v-chip>
        </template>
        <!-- Speed Index -->
        <!-- Time to interactive-->
        <template v-slot:item.LH_timetointeractive="{ item }">
          <v-chip
            :color="getColorLH_timetointeractive(item.LH_timetointeractive)"
            dark
            small
          >
            {{ item.LH_timetointeractive }}s
          </v-chip>
        </template>
        <!-- Time to interactive--->
        <!-- Lighthouse FCP  ok-->
        <template v-slot:item.LH_fcp="{ item }">
          <v-chip :color="getColorLH_fcp(item.LH_fcp)" dark small>
            {{ item.LH_fcp }}s
          </v-chip>
        </template>
        <!-- FCP--->
        <!-- Lighthouse TBT ok -->
        <template v-slot:item.LH_tbt="{ item }">
          <v-chip :color="getColorLH_tbt(item.LH_tbt)" dark small>
            {{ item.LH_tbt }}ms
          </v-chip>
        </template>
        <!-- TBT--->
        <!-- CLS ok-->
        <template v-slot:item.LH_cls="{ item }">
          <v-chip :color="getColorLH_cls(item.LH_cls)" dark small>
            {{ item.LH_cls }}
          </v-chip>
        </template>
        <!-- TBT--->
        <!-- Lighthouse LCP ok -->
        <template v-slot:item.LH_lcp="{ item }">
          <v-chip :color="getColorLH_lcp(item.LH_lcp)" dark small>
            {{ item.LH_lcp }}s
          </v-chip>
        </template>
        <!-- LCP--->
        <!-- lighthouse end-->
        <!-- PS LCP-->
        <template v-slot:item.PS_LCP="{ item }">
          <v-chip label small :class="item.PS_LCP">
            {{ item.PS_LCP_Percentile }}s
          </v-chip>
        </template>
        <!-- PS FID-->
        <template v-slot:item.PS_FID="{ item }">
          <v-chip label small :class="item.PS_FID">
            {{ item.PS_FID_Percentile }}ms
          </v-chip>
        </template>
        <!-- PS CLS-->
        <template v-slot:item.PS_CLS="{ item }">
          <v-chip small label :class="item.PS_CLS">
            {{ item.PS_CLS_Percentile }}
          </v-chip>
        </template>
        <!-- PS FCP-->
        <template v-slot:item.PS_FCP="{ item }">
          <v-chip small label :class="item.PS_FCP">
            {{ item.PS_FCP_Percentile }} s
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- edit site -->
          <router-link
            :to="{ name: 'EditSite', params: { slug: item.slug } }"
            class="link"
          >
            <v-icon small class="mr-2 edit">
              mdi-pencil
            </v-icon>
          </router-link>
          <!-- edit site -->
        </template>
        <!--source -->

        <!-- page or site -->
        <template v-slot:item.Scannedpage="{ item }">
          <!--   <strong 
              >
             google = {{ item.Scannedpage}}</strong><br>
            Site - {{ item.website+'/'  }}-->
          <!-- page successful -->
          <div
            v-if="
              item.website + '/' == item.Scannedpage ||
                item.website == item.Scannedpage
            "
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="green" v-bind="attrs" v-on="on"
                  >mdi-emoticon</v-icon
                >
              </template>
              <span>
                <v-icon color="green">mdi-emoticon</v-icon>Getting Page Results
                <br />Pagespeed = {{ item.Scannedpage }}<br />
                Site - {{ item.website + "/" }}</span
              >
            </v-tooltip>
          </div>

          <div v-else>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="orange" v-bind="attrs" v-on="on"
                  >mdi-emoticon-frown</v-icon
                >
              </template>
              <span>
                <v-icon color="orange">mdi-emoticon-frown</v-icon>Getting Site
                Origin <br />Pagespeed = {{ item.Scannedpage }}<br />
                Site - {{ item.website + "/" }}</span
              >
            </v-tooltip>
          </div>
        </template>
        <!-- page or site -->

        <template v-slot:top>
          <v-toolbar dark flat color="#95B93D">
            <v-toolbar-title v-if="!switch1">Pagespeed Insites</v-toolbar-title>
            <v-toolbar-title v-if="switch1">Lighthouse</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>
      </v-data-table>
      <!--      {{sites}}-->
    </v-card>
  </div>
</template>
<script>
import * as firebase from "firebase";
import moment from "moment";
import { fc, db } from "@/firebase/init";
export default {
  name: "sites",
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      sites: [],
      selected: ["pagespeed"],
      switch1: false, // lighthouse hide set to false
      switch2: false,
    };
  },
  computed: {
    computedHeaders() {
      let headers = [
       { text: "", value: "featured", width: "2" },
        { text: "", value: "Scannedpage", width: "2%" },
        { text: "Title", value: "title", width: "15%" },
        { text: "Unit", value: "unit", width: "5%" },
        { text: "Updated", value: "date", width: "10%" },
      ];
      if (this.switch2) {
        headers.push({ text: "Url", value: "website" });
      }
      if (!this.switch1) {
        
          headers.push({
            text: "Largest Contentful Paint (LCP)",
            value: "PS_LCP",
          }),
          headers.push({ text: "First Input Delay (FID)", value: "PS_FID" }),
          //  headers.push({ text: "FCP", value: "PS_FCP" })
          headers.push({
            text: "Cumulative Layout Shift (CLS)",
            value: "PS_CLS",
          });
      }
      if (this.switch1) {
        
          headers.push({ text: "Score", value: "LH_score" }),
          headers.push({ text: "LCP", value: "LH_lcp", class: "weight_25" }),
          headers.push({ text: "TBT", value: "LH_tbt", class: "weight_25" }),
          headers.push({ text: "FCP", value: "LH_fcp", class: "weight_15" }),
          headers.push({
            text: "Speed Index ",
            value: "LH_speedindex",
            class: "weight_15",
          }),
          headers.push({
            text: "Time to Interactive",
            value: "LH_timetointeractive",
            class: "weight_15",
          }),
          headers.push({ text: "CLS ", value: "LH_cls", class: "weight_5" });
           
      }
      headers.push({ text: "Edit", value: "actions", width: "5%" });
      return headers;
    },
  },
  methods: {
    deletesite(id) {
      this.sites = this.sites.filter((site) => {
        return site.id != id;
      });
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    //start traffic lights
    getColorlh_score(lh) {
      if (lh < 49) return "red";
      else if (lh < 89) return "orange";
      else return "green";
    },
    // speedindex
    getColorLH_speedindex(LH_speedindex) {
      if (LH_speedindex < 4.4) return "green";
      else if (LH_speedindex < 5.9) return "orange";
      else return "red";
    },
    getColorLH_timetointeractive(LH_timetointeractive) {
      if (LH_timetointeractive < 3.8) return "green";
      else if (LH_timetointeractive < 7.3) return "orange";
      else return "red";
    },
    // fcp ok
    getColorLH_fcp(lh) {
      if (lh < 2) return "green";
      else if (lh < 4) return "orange";
      else return "red";
    },
    // tbt ok
    getColorLH_tbt(lh) {
      if (lh < 300) return "green";
      else if (lh < 600) return "orange";
      else return "red";
    },
    // CLS NOT working
    getColorLH_cls(lh) {
      if (lh < 0.1) return "green";
      else if (lh < 0.25) return "orange";
      else return "red";
    },
    // lcp ok
    getColorLH_lcp(lcp) {
      if (lcp < 2.5) return "green";
      else if (lcp < 4.0) return "orange";
      else return "red";
      //return "pink";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    // fetch data from firestore
    db.collection("Sites")
      .orderBy("title")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());
          let site = doc.data();
          site.id = doc.id;
          this.sites.push(site);
          site.date = moment.unix(site.date.seconds).format("DD MMM YY");
          site.LH_speedindex = site.LH_speedindex.slice(0, -2);
          site.LH_fcp = site.LH_fcp.slice(0, -2);
          site.LH_timetointeractive = site.LH_timetointeractive.slice(0, -2);
          site.LH_lcp = site.LH_lcp.slice(0, -2);
          site.LH_tbt = site.LH_tbt.slice(0, -3).replace(/,/g, "");
        });
      });
  },
};
</script>
<style>
.AVERAGE {
  background: orange !important;
  color: white !important;
}
.SLOW {
  background: RED !important;
  color: white !important;
}
.FAST {
  background: #5ab55d !important;
  color: white !important;
}
.v-chip--label .v-chip__content {
  width: 55px;
  text-align: center !important;
}
.v-chip .v-chip__content {
  min-width: 35px;
  text-align: center !important;
}
.featured {
}
.link:link {
  text-decoration: none;
}
.link:link .edit,
.link:visited .edit {
  color: rgba(0, 0, 0, 0.2);
}
.link:hover .edit {
  color: orange;
}
.float-right {
  margin-left: 20px;
}
.avatar {
  color: white;
  font-size: 12px;
}
.weight_5 {
  background: #f5f5f5;
  text-align: center;
}
.weight_15 {
  background: #eeeeee;
  text-align: center;
}
.weight_25 {
  background: #e0e0e0;
  text-align: center !important;
}
</style>
