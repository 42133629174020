var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-switch',{staticClass:"float-right",attrs:{"label":"Lighthouse","inset":"","small":""},model:{value:(_vm.switch1m),callback:function ($$v) {_vm.switch1m=$$v},expression:"switch1m"}})],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.computedHeaders,"items":_vm.sites,"items-per-page":30,"item-key":"sites","sort-by":['title'],"sort-desc":[false],"search":_vm.search},scopedSlots:_vm._u([{key:"item.featured",fn:function(ref){
var item = ref.item;
return [(item.featured === 'true')?[_c('v-icon',{attrs:{"medium":"","color":"orange darken-5"}},[_vm._v("mdi-star")])]:_vm._e()]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Site', params: { slug: item.slug } }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.PS_LCP",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.m_PS_LCP,attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.m_PS_LCP_Percentile)+"s ")])]}},{key:"item.m_PS_FID",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.m_PS_FID,attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.m_PS_FID_Percentile)+"ms ")])]}},{key:"item.m_PS_CLS",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.PS_CLS,attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.m_PS_CLS_Percentile)+" ")])]}},{key:"item.m_PS_FCP",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.PS_FCP,attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.m_PS_FCP_Percentile)+" s ")])]}},{key:"item.m_LH_score",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"avatar",attrs:{"color":_vm.getColorlh_score(item.m_LH_score),"dark":"","small":"","size":"25"}},[_vm._v(" "+_vm._s(item.m_LH_score)+" ")])]}},{key:"item.LH_speedindex",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_speedindex(item.LH_speedindex),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.LH_speedindex)+"s ")])]}},{key:"item.LH_timetointeractive",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_timetointeractive(item.LH_timetointeractive),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.LH_timetointeractive)+"s ")])]}},{key:"item.LH_fcp",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_fcp(item.LH_fcp),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.LH_fcp)+"s ")])]}},{key:"item.LH_tbt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_tbt(item.LH_tbt),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.LH_tbt)+"ms ")])]}},{key:"item.LH_cls",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_cls(item.LH_cls),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.LH_cls)+" ")])]}},{key:"item.LH_lcp",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorLH_lcp(item.LH_lcp),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.LH_lcp)+"s ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'EditSite', params: { slug: item.slug } }}},[_c('v-icon',{staticClass:"mr-2 edit",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}},{key:"item.Scannedpage",fn:function(ref){
var item = ref.item;
return [(
              item.website + '/' == item.Scannedpage ||
                item.website == item.Scannedpage
            )?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-emoticon")])]}}],null,true)},[_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-emoticon")]),_vm._v("Getting Page Results "),_c('br'),_vm._v("Pagespeed = "+_vm._s(item.Scannedpage)),_c('br'),_vm._v(" Site - "+_vm._s(item.website + "/"))],1)])],1):_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v("mdi-emoticon-frown")])]}}],null,true)},[_c('span',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-emoticon-frown")]),_vm._v("Getting Site Origin "),_c('br'),_vm._v("Pagespeed = "+_vm._s(item.Scannedpage)),_c('br'),_vm._v(" Site - "+_vm._s(item.website + "/"))],1)])],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dark":"","flat":"","color":"#3D7EC1"}},[(!_vm.switch1m)?_c('v-toolbar-title',{staticStyle:{"width":"25%"}},[_vm._v("Pagespeed Insites")]):_vm._e(),(_vm.switch1m)?_c('v-toolbar-title',{staticStyle:{"width":"25%"}},[_vm._v("Lighthouse")]):_vm._e(),_c('v-spacer'),_c('v-text-field',{staticStyle:{"float":"right","width":"33%"},attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }