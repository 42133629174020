<template>
  <div class="updated">
    <div class="text-right" v-if="!loading">
      <v-icon small>mdi-av-timer</v-icon
      ><strong> last updated : {{ this.siteData[0].ago }} </strong>
    </div>
    <div class="small text-right" v-if="!loading">
      {{ this.siteData[0].date }}
    </div>
    <!--  <div class="small text-right test" >site id : {{this.$props.siteid}}</div>-->
    <div class="small text-right test">
      <v-btn
        class="refresh_button"
        v-bind:href="
          'https://us-central1-jpi-core-web-vitals.cloudfunctions.net/webvitals-combi?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl
        "
        target="_blank"
        x-small
        :loading="loading2"
        :disabled="loading2"
        depressed
        color="normal"
        @click="loader = 'loading2'"
        ><v-icon x-small>mdi-refresh</v-icon>refresh site
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>

  
   <v-btn
        class="refresh_button"
        v-bind:href="
          'https://us-central1-jpi-core-web-vitals.cloudfunctions.net/webvitals-mobile?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl
        "
        target="_blank"
        x-small
        :loading="loading2"
        :disabled="loading2"
        depressed
        color="normal"
        @click="loader = 'loading2'"
        ><v-icon x-small>mdi-refresh</v-icon>mobile remote
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
       <v-btn
        class="refresh_button"
        v-bind:href="
          'http://localhost:5001/jpi-core-web-vitals/us-central1/webvitals-mobile?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl
        "
        target="_blank"
        x-small
        :loading="loading2"
        :disabled="loading2"
        depressed
        color="normal"
        @click="loader = 'loading2'"
        ><v-icon x-small>mdi-refresh</v-icon>mobile local
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
<!--
       <v-btn
        class="refresh_button"
        v-bind:href="
          ' <v-btn
        class="refresh_button"
        v-bind:href="
          'https://us-central1-jpi-core-web-vitals.cloudfunctions.net/webvitals-mobile?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl
        "
        target="_blank"
        x-small
        :loading="loading2"
        :disabled="loading2"
        depressed
        color="normal"
        @click="loader = 'loading2'"
        ><v-icon x-small>mdi-refresh</v-icon>mobile remote
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl
        "
        target="_blank"
        x-small
        :loading="loading2"
        :disabled="loading2"
        depressed
        color="normal"
        @click="loader = 'loading2'"
        ><v-icon x-small>mdi-refresh</v-icon>test desktop
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>

    

      <v-bt--
        class="refresh_button"
        v-bind:href="
          'http://localhost:5001/jpi-core-web-vitals/us-central1/webvitals-mobile?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl
        "
        target="_blank"
        x-small
        :loading="loading2"
        :disabled="loading2"
        depressed
        color="normal"
        @click="loader = 'loading2'"
        ><v-icon x-small>mdi-refresh</v-icon>test mobile
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-bt-->
     
       <v-btn
        class="refresh_button"
        v-bind:href="
          'http://localhost:5001/jpi-core-web-vitals/us-central1/webvitals-combi?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl
        "
        target="_blank"
        x-small
        :loading="loading2"
        :disabled="loading2"
        depressed
        color="normal"
        @click="loader = 'loading2'"
        ><v-icon x-small>mdi-refresh</v-icon>Refresh Local
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </div>




  </div>
</template>
<script>
// this adds the last updated from a site id
//import * as firebase from "firebase";
import firebase from "firebase/app";
import { fc, db } from "@/firebase/init";
import moment from "moment";

export default {
  name: "updated",
  props: ["siteid", "siteurl"],
  data() {
    return {
      siteData: [],
      loading: true,
      loader: null,
      loading2: false,
      comboSingleSite: [],
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
  methods: {
    callupdate() {
      const comboSingleSite = firebase.functions().httpsCallable("single_call");
     comboSingleSite({site_id: this.$props.siteid})
      console.log("sorted");
    },

    // call firestore function
    readSite() {
      this.siteData = [];
      const sid = this.$props.siteid;
      db.collection("Sites")
        .doc(sid)
        .collection("pagespeed")
        .orderBy("date", "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.siteData.push({
              id: doc.id,
              date: moment
                .unix(doc.data().date.seconds)
                .format("MMM Do YY [at]  h:mm a"),
              ago: moment.unix(doc.data().date.seconds).fromNow(),
            });
            //  console.log(doc.id, " => ", doc.data());
            this.loading = false;
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  },
  mounted() {
    this.readSite();
  },
};
</script>
<style scoped>
.refreshtext {
  font-size: 10px;
}
.refresh:link,
.refresh:visited {
  background: orange;
  text-decoration: none;
  padding: 0px 5px;
  font-size: 10px;
  border-radius: 5px;
  color: white;
  margin-right: 2px;
}
.refresh:hover {
  background: black;
}
.test {
  color: orange;
}
.testlocal {
  color: red;
}
.refreshlocal:link,
.refreshlocal:visited {
  background: red;
  text-decoration: none;
  padding: 0px 5px;
  font-size: 10px;
  border-radius: 5px;
  color: white;
  margin-right: 2px;
}
.refreshlocal:hover {
  background: black;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
.refresh_button:hover {
  text-decoration: none;
  margin-left: 10px !important;
}
.refresh_button {
  text-decoration: none;
  margin-left: 10px !important;
}
</style>
