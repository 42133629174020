<template>
  <v-app>
    <v-main>
      <v-container v-if="dashboard[0]">
        <div>
          <!-- {{dashboard[0]}} -->
          <!-- Page Speed Insites -->
          <v-row class="greyback">
            <v-col md="12"
              ><h5>
                Page Speed Insights {{ dashboard[0].Overall_Category }}
              </h5></v-col
            >
            <!-- FCP -->
            <v-col md="4" v-if="dashboard[0].lcpCategory">
              <v-card outlined>
                <v-card-title v-bind:class="dashboard[0].lcpCategory"
                  ><h4>LCP</h4></v-card-title
                >
                <v-card-text>
                  <div class="small">Largest Contentful Paint</div>
                  <!--  <div class="score "> {{ dashboard[0].lcpPercentile/1000 }}s</div>-->
                  <div class="score" v-bind:class="dashboard[0].lcpCategory">
                    {{ (dashboard[0].lcpPercentile / 1000).toFixed(1) }} s
                  </div>
                  <div class="chart">
                    <dashboardGraph
                      title="LCP"
                      v-bind:passedData="dashboard[0].lcp"
                    ></dashboardGraph>
                  </div>
                  <!-- {{ dashboard[0].lcp }} -->
                </v-card-text>
              </v-card>
            </v-col>
            <!--
            <v-col md="4"  v-if="dashboard[0].fcpCategory ">
              <v-card dark>
                <v-card-title ><h4>FCP</h4></v-card-title>
                <v-card-text
                  ><div class="small" v-bind:class="dashboard[0].fcpCategory">First Contentful Paint</div>
         <div class="small">{{dashboard[0].fcpPercentile }}</div>
                  <div class="score" v-bind:class="dashboard[0].fcpCategory">
                  {{ (dashboard[0].fcpPercentile/1000).toFixed(1) }} s
                  </div>
                  <div class="chart">
                    <dashboardGraph
                      title="FCP"
                      v-bind:passedData="dashboard[0].fcp"
                    ></dashboardGraph>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>-->
            <v-col md="4" v-if="dashboard[0].fidCategory">
              <v-card outlined>
                <v-card-title v-bind:class="dashboard[0].fidCategory"
                  ><h4>FID</h4></v-card-title
                >
                <v-card-text>
                  <div class="small">
                    First Input Delay
                  </div>
                  <!-- <div class="small ">{{ dashboard[0].fidPercentile}}</div>-->
                  <div class="score " v-bind:class="dashboard[0].fidCategory">
                    <!--  {{ dashboard[0].lhFirstInputDelay }}-->
                    {{ dashboard[0].fidPercentile }} ms
                  </div>
                  <div class="chart">
                    <dashboardGraph
                      title="LCP"
                      v-bind:passedData="dashboard[0].fid"
                    ></dashboardGraph>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="4" v-if="dashboard[0].clsCategory">
              <v-card outlined>
                <v-card-title v-bind:class="dashboard[0].clsCategory"
                  ><h4>CLS</h4></v-card-title
                >
                <v-card-text
                  ><div class="small">
                    Cumulative Layout Shift
                  </div>
                  <div class="score" v-bind:class="dashboard[0].clsCategory">
                    <!-- {{ dashboard[0].lhCumulativeLayoutShift }}-->
                    {{ dashboard[0].clsPercentile / 100 }}
                  </div>
                  <div class="chart">
                    <dashboardGraph
                      title="LCP"
                      v-bind:passedData="dashboard[0].cls"
                    ></dashboardGraph>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div> </v-container
      ><dashboard_single_lighthouse_panel title="LCP" v-bind:siteid="siteid">
      </dashboard_single_lighthouse_panel>
    </v-main>
  </v-app>
</template>
<script>
import Vue from "vue";
import { fc, db } from "@/firebase/init";
import moment from "moment";
import dashboardGraph from "@/components/site_items_components/graphs/dashboardGraph";
import dashboard_single_lighthouse_panel from "@/components/site_items_components/graphs/single_site_lighthouse_panel";
export default {
  components: {
    //  PerformanceScore
    dashboardGraph,
    dashboard_single_lighthouse_panel,
  },
  name: "SiteDashboard",
  props: {
    siteid: { type: String },
    sitetitle: { type: String },
    siteurl: { type: String },
  },
  data() {
    return {
      dashboard: [],
      sid: [],
    };
  },
  methods: {},
  //Created
  mounted() {
    //  let sid = "K4KiLsWxCNUuVwepI6kc";
    let loadplatform = null;
    let sid = this.$props.siteid;
    let platform = this.$store.state.platform;
    // alert(platform);

    if (platform == "mobile") {
      loadplatform = "m_pagespeed";
    } else {
      loadplatform = "pagespeed";
    }
    // alert(loadplatform);
    let dashdata = db
      .collection("Sites")
      .doc(sid)
      .collection(loadplatform)
      .orderBy("date", "desc")
      .limit(1);
    dashdata.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        console.log(doc.data(), doc.id);
        let dashboard = doc.data();
        dashboard.id = doc.id;
        dashboard.date = moment
          .unix(doc.data(0).date.seconds)
          .format("DD/MM/YY");
        dashboard.ago = moment.unix(doc.data(0).date.seconds).fromNow();
        dashboard.test = doc.data(0).lighthouse[0].SpeedIndex;
        dashboard.Overall_Category = doc.data(
          0
        ).loadingExperience[0].Overall_Category;
        //LCP Scores
        {
          dashboard.lcpCategory = doc.data(0).loadingExperience[0].LCP_Category;
          dashboard.lcpPercentile = doc.data(
            0
          ).loadingExperience[0].LCP_Percentile;
          dashboard.lcpPoor = Math.round(
            doc.data(0).loadingExperience[0].LCP_Poor * 100
          );
          dashboard.lcpMed = Math.round(
            doc.data(0).loadingExperience[0].LCP_Med * 100
          );
          dashboard.lcpGood = Math.round(
            doc.data(0).loadingExperience[0].LCP_Good * 100
          );
          dashboard.lcp = [];
          dashboard.lcp.push(
            dashboard.lcpPoor,
            dashboard.lcpMed,
            dashboard.lcpGood
          );
        }
        //FID
        {
          dashboard.fidCategory = doc.data(0).loadingExperience[0].FID_Category;
          dashboard.fidPercentile = doc.data(
            0
          ).loadingExperience[0].FID_Percentile;
          dashboard.fidPoor = Math.round(
            doc.data(0).loadingExperience[0].FID_Poor * 100
          );
          dashboard.fidMed = Math.round(
            doc.data(0).loadingExperience[0].FID_Med * 100
          );
          dashboard.fidGood = Math.round(
            doc.data(0).loadingExperience[0].FID_Good * 100
          );
          dashboard.fid = [];
          dashboard.fid.push(
            dashboard.fidPoor,
            dashboard.fidMed,
            dashboard.fidGood
          );
        }
        //CLS
        {
          dashboard.clsCategory = doc.data(0).loadingExperience[0].CLS_Category;
          dashboard.clsPercentile = doc.data(
            0
          ).loadingExperience[0].CLS_Percentile;
          dashboard.clsPoor = Math.round(
            doc.data(0).loadingExperience[0].CLS_Poor * 100
          );
          dashboard.clsMed = Math.round(
            doc.data(0).loadingExperience[0].CLS_Med * 100
          );
          dashboard.clsGood = Math.round(
            doc.data(0).loadingExperience[0].CLS_Good * 100
          );
          dashboard.cls = [];
          dashboard.cls.push(
            dashboard.clsPoor,
            dashboard.clsMed,
            dashboard.clsGood
          );
        }
        // FCP
        {
          dashboard.fcpCategory = doc.data(0).loadingExperience[0].FCP_Category;
          dashboard.fcpPercentile = doc.data(
            0
          ).loadingExperience[0].FCP_Percentile;
          dashboard.fcpPoor = Math.round(
            doc.data(0).loadingExperience[0].FCP_Poor * 100
          );
          dashboard.fcpMed = Math.round(
            doc.data(0).loadingExperience[0].FCP_Med * 100
          );
          dashboard.fcpGood = Math.round(
            doc.data(0).loadingExperience[0].FCP_Good * 100
          );
          dashboard.fcp = [];
          dashboard.fcp.push(
            dashboard.fcpPoor,
            dashboard.fcpMed,
            dashboard.fcpGood
          );
        }
        //Lighthouse
        dashboard.lhScore = doc.data(0).lighthouse[0].PerformanceScore;
        dashboard.lhinteractive = doc.data(0).lighthouse[0].TimetoInteractive;
        dashboard.lhispeedindex = doc.data(0).lighthouse[0].SpeedIndex;
        dashboard.lhFirstContentfulPaint = doc.data(
          0
        ).lighthouse[0].FirstContentfulPaint;
        dashboard.lhFirstInputDelay = doc.data(0).lighthouse[0].FirstInputDelay;
        dashboard.lhCumulativeLayoutShift = doc.data(
          0
        ).lighthouse[0].CumulativeLayoutShift;
        dashboard.lhLargestContentfulPaint = doc.data(
          0
        ).lighthouse[0].LargestContentfulPaint;
        dashboard.lhTotalBlockingTime = doc
          .data(0)
          .lighthouse[0].TotalBlockingTime.replace(/,/g, "");
        return this.dashboard.push(dashboard);
      });
    });
  },
  // Data
};
</script>
<style scoped>
h4 {
  text-align: center;
  font-size: 18px;
}
.subtext {
  color: grey;
  text-align: center;
  font-size: 15px;
}
.inlineb {
  display: inline-block;
  vertical-align: top;
  height: 350px;
  margin-bottom: 20px;
}
.chart {
  color: red;
}
h4 {
  color: white;
  text-align: center !important;
  width: 100%;
  padding: 5px;
  margin: 0px !important;
}
.score {
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  color: black;
}
.AVERAGE {
  background-color: orange;
}
.score.FAST,
.small.FAST,
h4.FAST {
  color: green !important;
  background: none !important;
}
.score.SLOW,
.small.SLOW,
h4.SLOW {
  color: red !important;
  background: none !important;
}
.score.AVERAGE,
.small.AVERAGE,
h4.AVERAGE {
  color: ORANGE !important;
  background: none !important;
}
.FAST,
.GOOD {
  background-color: green;
}
.SLOW,
.POOR {
  background-color: red;
}
.small {
  font-size: 12px;
  text-align: center;
  margin: 0px;
  color: black;
  font-weight: bold;
}
.v-card__title {
  padding: 0px;
  margin: 0px;
  line-height: 1;
}
.weight {
  color: grey;
}
.blacktext {
  color: black;
}
.whitetext {
  color: white;
}
.greyback {
  background: #edf0f0;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid lightgrey;
}
.orangeback {
  background: pink;
}
</style>
