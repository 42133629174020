<template>
<!-- This View is themain landing page that pulls in other graphs etc after login-->
<!-- Status : needs some additional components -->
  <v-container>
    <v-row 
      ><v-col><h1>Web Vitals  <span :class="this.$store.state.platform">{{this.$store.state.platform}}</span></h1></v-col>
     
    </v-row>
   
    <v-divider></v-divider>
    <br />
    <v-row>
      <v-col cols="12">
        <div
          v-if="user.data.displayName"
          class="alert alert-success"
          role="alert"
        >
          <strong>Welcome {{ user.data.displayName }} </strong> <br />
        </div>
      </v-col>

      <v-col cols="9"> 
        <v-card dense  
      > 
          <v-card-title
            ><v-icon medium color="orange darken-5">mdi-star</v-icon>
            <h6>Featured Sites</h6></v-card-title
          >
          <sitesdashboard> </sitesdashboard
        ></v-card>
       
      </v-col>
      <v-col cols="3">
        <v-card color="grey lighten-3" >
          <v-card-title >
            <v-icon medium color="#595959">mdi-lighthouse</v-icon>
            
            
            <h5 color="#595959">
             
             Lighthouse Issues
            </h5></v-card-title
          >
        
          
          <Sites_dashboard_issues></Sites_dashboard_issues>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import {fc, db} from "@/firebase/init";
import sitesdashboard from "@/components/Sites_dashboard";
import Sites_dashboard_issues from "@/components/Sites_dashboard_issues";
export default {
  data() {
    return {
      sites: [],
    };
  },
  created() {
    // fetch data from firestore
  },
  computed: {
    //  map `this.user` to `this.$store.getters.user`,
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    sitesdashboard,
    Sites_dashboard_issues,
    // Sites_table,
    // Sites_select
  },
};
</script>

<style scoped>

.smalllogo {
  height: 30px;
  color: white;
  margin-left:-5px;
  margin-right:140px
}

.web {
  color:#3D7EC1;
}

.vitals {
  color: #95B93C;
}

.mobile h1{ color: #95B93C;}

.desktop h1{color:#;}
</style>
