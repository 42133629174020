<template>
  <div class="container">
    <v-form action="#" @submit.prevent="submit">
      <v-container>
        <v-row>
          <v-col class="center">
            <img
              src="../assets/images/web-vitals-logo.svg"
              class="landing_logo "
            />
            <h1>
              Welcome to <span class="web">Web</span
              ><span class="vitals"> Vitals</span>
            </h1>
            <br>

            <v-btn large color="primary" @click="socialLogin" class="social-button"
              > <v-icon small left >mdi-google</v-icon>Login</v-btn
            >
            <!-- <p>Please login to site.</p>-->

            <!--<v-text-field label="email" v-model="form.email"> </v-text-field>
            <v-text-field
              label="password"
              id="password"
              type="password"
              class="validate"
              name="password"
              required
              v-model="form.password"
            >
            </v-text-field>
            <v-btn type="submit" elevation="2" medium>Submit</v-btn>
            <router-link to="register">
              <v-btn class="float-right">Register</v-btn>
            </router-link>-->
               <!-- <div v-if="error" class="alert alert-danger">{{ error }}</div>-->
          </v-col>
        </v-row>
       
      </v-container>
    </v-form>
  </div>
</template>
<script>
import firebase from "firebase";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          this.$router.replace({
            name: "Dashboard",
          });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },

    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      const customParameters = "hd:jpimedia.co.uk";
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          this.$router.replace("Dashboard");
        })
        .catch((err) => {
          alert("Oops. " + err.message);
        });
    },
  },
};
</script>

<style scoped>
.landing_logo {
  height: 200px;
}

.web {
  color: #3d7ec1;
}

.vitals {
  color: #95b93c;
}

.login {
  margin-top: 40px;
}
input {
  margin: 10px 0;
  width: 20%;
  padding: 15px;
}

p {
  margin-top: 40px;
  font-size: 13px;
}
p a {
  text-decoration: underline;
  cursor: pointer;
}

.center {
  text-align: center;
}
</style>
