<template>
  <div>
    <div>
      <zingchart ref="myChart" :data="chartConfig"> </zingchart>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fc, db } from "@/firebase/init";
import moment from "moment";
import zingchartVue from "zingchart-vue";
export default {
  name: "GraphPagespeedInsightsLCP",
  props: ["siteurl", "siteid", "passeddata", "title", "sitetitle"],
  components: {
    zingchart: zingchartVue,
  },
  data() {
    return {};
  },
  computed: {
    chartConfig() {
      return {
        type: "bar",
        stacked: true,
        "stack-type": "100%",
        title: {
          text: "CLS ",
          fontColor: "black",
        },

        subtitle: {
          text: "Cumulative Layout Shift ",
          fontColor: "grey",
        },

        plot: {
          mode: "normal",
          aspect: "histogram",
           

          "bar-width": "25px",
          tooltip: {
            decimals: 2,
            text: "%kl <br> %t <br>%v %",
            placement: "node:top",
            padding: "10%",
            "border-radius": "5px",
          },

          animation: {
            delay: 500,
            effect: "ANIMATION_SLIDE_BOTTOM",
            speed: "1000",
            method: "2",
            sequence: "0",
          },
        },

        "scale-x": {
          zooming: true,
          // "zoom-to": [50, 100],
          step: "day",
          transform: {
            type: "date",
            all: "%d %M %y",
          },
        },
        "scale-y": {
          //decimals: 2,
          zooming: false,
        },
        preview: {
          "background-color": "#F5F7F3",
          "preserve-zoom": false,
          mask: {
            backgroundColor: "white",
            alpha: 0.8,
          },
          handle: {
            "border-width": 1,
          },
        },
        legend: {},
        utc: true,
        timezone: 0,
        series: [
          {
            values: this.$props.passeddata.map((a) => [a.date, a.CLSGood*100]),
            "background-color": "green",
            text: "Good",
          },
          {
            values: this.$props.passeddata.map((b) => [b.date, b.CLSMed*100]),
            "background-color": "orange",
            text: "Med",
          },
          {
            values: this.$props.passeddata.map((c) => [c.date, c.CLSPoor*100]),
            "background-color": "red",
            text: "Poor",
          },
        ],

        //finish
      };
    },
  },
};
</script>
<style></style>
