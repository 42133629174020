<template>
  <v-footer dark v-if="user.loggedIn">
    <v-main>
      <v-container>
        <v-row
          ><v-col><h5>Links</h5></v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-list dense>
              <v-subheader
                >
                
                 <v-icon medium color="blue-grey lighten-3"
                  >mdi-lighthouse</v-icon
                >
                
               
                <h5 class="footer_h5">Documentation</h5></v-subheader
              >
              <v-list-item dense
                ><a
                  href="https://googlechrome.github.io/lighthouse/scorecalc/"
                  target="blank"
                  class="footer_link"
                >
                  <v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Scoring Caculator</a
                ></v-list-item
              >
              <v-list-item dense>
                <a
                  href="https://web.dev/performance-scoring/"
                  target="blank"
                  class="footer_link"
                  ><v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Performance scoring</a
                >
              </v-list-item>
              <v-subheader
                >  <v-icon medium color="blue-grey lighten-3"
                  >mdi-lighthouse</v-icon
                >
                <h5 class="footer_h5">Performance Indicators</h5></v-subheader
              >
              <v-list-item dense
                ><a
                  href="https://web.dev/first-contentful-paint/"
                  target="blank"
                  class="footer_link"
                >
                  <v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  First Contentful Paint</a
                ></v-list-item
              >

              <v-list-item dense
                ><a
                  href="https://web.dev/speed-index/"
                  target="blank"
                  class="footer_link"
                >
                  <v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Speed Index</a
                ></v-list-item
              >
              <v-list-item dense
                ><a
                  href="https://web.dev/lcp/"
                  target="blank"
                  class="footer_link"
                >
                  <v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Largest Contentful Paint</a
                ></v-list-item
              >
              <v-list-item dense
                ><a
                  href="https://web.dev/interactive/"
                  target="blank"
                  class="footer_link"
                >
                  <v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Time to Interactive</a
                ></v-list-item
              >
              <v-list-item dense
                ><a
                  href="https://web.dev/lighthouse-total-blocking-time/"
                  target="blank"
                  class="footer_link"
                >
                  <v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Total Blocking Time</a
                ></v-list-item
              >
              <v-list-item dense
                ><a
                  href="https://web.dev/cls/"
                  target="blank"
                  class="footer_link"
                >
                  <v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Cumulative Layout Shift</a
                ></v-list-item
              >
            </v-list>
          </v-col>
          <v-col cols="3">
            <v-list dense>
              <v-subheader>
                <v-icon medium color="blue-grey lighten-3"
                  >mdi-newspaper-variant-outline</v-icon
                >

                <h5 class="footer_h5">
                  Optamisation Articles
                </h5></v-subheader
              >

              <v-list-item dense>
                <a
                  href="https://www.smashingmagazine.com/2021/01/smashingmag-performance-case-study/"
                  target="blank"
                  class="footer_link"
                  ><v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Better Smashing Mag Performance</a
                >
              </v-list-item>

              <v-list-item dense
                ><a
                  href="https://www.smashingmagazine.com/2021/01/front-end-performance-2021-free-pdf-checklist/"
                  target="blank"
                  class="footer_link"
                >
                  <v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Performance Checklist 2021</a
                ></v-list-item
              >
               <v-list-item dense
                ><a
                  href="https://github.com/GoogleChrome/lighthouse/releases/tag/v8.0.0"
                  target="blank"
                  class="footer_link"
                >
                  <v-icon x-small color="#595959">mdi-open-in-new</v-icon>
                  Lighthouse v8 release notes</a
                ></v-list-item
              >
              
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
<style scoped>
.smalllogo {
  height: 30px;
  color: white;
}
.footer_title {
  color: white;
}
.footer_list {
  color: white;
}
.footer_h5 {
  color: white; margin-left:5px
}
.footer_link:link,
.footer_link:visited {
  color: #546e7a;
  text-decoration: none;
}

.footer_link:hover {
  color: white;
  text-decoration: none;
}
</style>
