<template>
  <v-app id="app">
    <navbar />
     <router-view></router-view>
    <Footer />
  </v-app>
</template>

<script>
import navbar from "@/components/Navbar";
import Footer from "@/components/Footer";


export default {
  components: {
    navbar,
    Footer,
  },
};
</script>

<style lang="scss">
@import "assets/scss/global.scss";
</style>
