<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col col="1" style="margin-left:-25px">
            <v-breadcrumbs :items="items">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text }}
                </v-breadcrumbs-item>
                
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>
    
       
          <h1>Add a new site</h1>
  
    
        <v-row 
          ><v-col cols="12"
              ><Site_add_component></Site_add_component>
        </v-col></v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Site_add_component from "@/components/Site_add_component";
import {fc, db} from "@/firebase/init";
import { mapGetters } from "vuex";

export default {
  name: "Site",
  data() {
    return {
      site: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/dashboard",
        },
        {
          text: "Sites",
          disabled: false,
          href: "/sites",
        },
        {
          text: "Add a new site",
          disabled: true,
          href: "sites",
        },
      ],
    };
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    Site_add_component,
  },
};
</script>
