<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <!-- breadcrumbs -->
          <v-col col="1" style="margin-left:-25px">
            <v-breadcrumbs :items="items" col="1">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-col>
          <!-- breadcrumbs -->
        </v-row>
        <v-row v-if="this.site">
          <v-col>
            <h1>Edit {{ site.title }}</h1></v-col
          >
        </v-row>

        <!-- added form -->
     
        <v-form @submit.prevent="UpdateSite">
          <v-row>
            <v-col
              >
              <v-checkbox
                name="featured"
                label="Featured Site"
                false-value="false"
                true-value="true"
                v-model="site.featured"
              >
              </v-checkbox>

              <!-- site title -->
              <v-text-field
                label="Site title:"
                name="title"
                v-model="site.title"
                :placeholder="site.title"
              >
              </v-text-field>
              <!-- site url-->
              <v-text-field
                label="Website Url:"
                name="website"
                v-model="site.website"
                placeholder="www.bbc.co.uk etc "
                type="url"
              >
              </v-text-field
            ></v-col>
          </v-row>
          <!-- Switch -->
          <!--
          <v-row>
            <v-checkbox
              v-model="checked"
              label="Internal"
              name="source"
            ></v-checkbox
          ></v-row>
          <v-row v-show="checked">
            <v-col>
              <strong> Select the internal JPI region.</strong><br />
              <v-radio-group v-model="unit" column dense>
                <v-radio label="Ireland" value="Ireland" dense></v-radio>
                <v-radio label="Midlands" value="Midlands"></v-radio>
                <v-radio label="North East" value="North East"></v-radio>
                <v-radio label="North West" value="North West"></v-radio>
                <v-radio label="NMSY" value="NMSY"></v-radio>
                <v-radio label="Scotland" value="Scotland"></v-radio>
                <v-radio label="South" value="South"></v-radio>
                <v-radio label="Yorkshire" value="Yorkshire"></v-radio>
              </v-radio-group>

           
            </v-col>
           
          </v-row>   -->
          <v-textarea
            filled
            name="add-details"
            label="Details"
            value="A brief site description"
            dense
          ></v-textarea>
          <v-btn color="primary" elevation="2" class="btn" @click="UpdateSite"
            ><v-icon dark left> mdi-update</v-icon>Update
          </v-btn>
        </v-form>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {fc, db} from "@/firebase/init";
import slugify from "slugify";

export default {
  name: "EditSite",
  data: () => ({
    site: [],
    website: [],
    checked: [],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/dashboard",
      },
      {
        text: "Sites",
        disabled: false,
        href: "/sites",
      },
      {
        text: "Edit",
        disabled: false,
        href: "/sites",
      },
    ],
  }),

  created() {
    let ref = db
      .collection("Sites")
      .where("slug", "==", this.$route.params.slug);
    ref.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        //console.log(doc.data());
        this.site = doc.data();
        this.site.id = doc.id;
      });
    });
  },
  methods: {
    UpdateSite() {
      db.collection("Sites")
        .doc(this.site.id)
        .update({
          title: this.site.title,
          website: this.site.website,
          unit: this.site.unit,
          //  slug: this.slug,
          featured: this.site.featured,
          //   featured: this.site.featured.toString(),
        })
        .then(() => {
          this.$router.push({ name: "Sites" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
