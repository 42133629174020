<template>
  <v-app>
    <v-main>
      <v-container>
        <v-breadcrumbs :items="items" col="1" style="margin-left:-25px">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-row>
          <v-col>
            <div v-if="site">
              <h1>{{ site.title }} <span :class="this.$store.state.platform">{{this.$store.state.platform}}</span></h1>
            </div>
            <div>
              <router-link
                :to="{ name: 'EditSite', params: { slug: site.slug } }"
                class="refresh"
              >
                Edit {{ site.title }}
              </router-link>
            </div>

            <div v-if="site">
              url :
              <a v-if="site" v-bind:href="site.website" target="blank"
                >{{ site.website }}
              </a>
              <v-icon x-small color="darken-2">mdi-open-in-new</v-icon><br />
              site id : {{ site.id }}
            </div>
            <br>

            <!-- added -->

<div
            v-if="
              site.website + '/' == site.Scannedpage ||
                site.website == site.Scannedpage
            "
          >
          <v-alert
  type="success"

                >Getting Page Results - use pagespeed & lighthouse
                <br />Pagespeed = {{ site.Scannedpage }}<br />
                Site - {{ site.website + "/" }}</v-alert>
           
          </div>

          <div v-else>

             <v-alert
  
  type="warning"

                >Getting Site Origins - use lighthouse only
                <br />Pagespeed = {{ site.Scannedpage }}<br />
                Site - {{ site.website + "/" }}</v-alert>
         
           
          </div>

            <!-- added -->
            
          </v-col>
          <v-col
            ><updated
              v-bind:siteid="site.id"
              v-if="site.id"
              v-bind:siteurl="site.website"
            ></updated>
          </v-col>
        </v-row>

        <v-divider class="devider"></v-divider>
        <v-tabs v-model="tab">
          <!-- Dasboard -->
          <v-tab v-model="tab">
            <v-icon small dark left>mdi-earth</v-icon>Dashboard</v-tab
          >
          <v-tab-item class="top_space dashboard tab">
            <SiteDashboard
              v-bind:siteid="site.id"
              v-if="site.id"
              v-bind:sitetitle="site.title"
              v-bind:siteurl="site.website"
              v-bind="$attrs"
            >
            </SiteDashboard>
          </v-tab-item>

          <!-- Pagespeed Insights-->
          <v-tab v-model="tab">
            <v-icon small dark left>mdi-gauge</v-icon>Pagespeed Insights</v-tab
          >

          <v-tab-item class="top_space tab">
            <PagespeedInsights
              v-bind:siteid="site.id"
              v-if="site.id"
              v-bind:sitetitle="site.title"
              v-bind:siteurl="site.website"
            ></PagespeedInsights
          ></v-tab-item>

          <!-- Search Console -->
          <!--
          <v-tab v-model="tab"
            ><v-icon small dark left>mdi-magnify</v-icon>Search console</v-tab
          >

          <v-tab-item class="top_space searchconsole tab">
            <Search_console v-if="site.id"></Search_console
          ></v-tab-item>
          -->
          <!-- Google Analytics-->
          <!--
          <v-tab v-model="tab">
            <v-icon small dark left>mdi-google-analytics</v-icon> Google
            Analytics</v-tab
          >
          <v-tab-item class="top_space tab"><Google_A v-if="site.id"></Google_A></v-tab-item>
          -->
        </v-tabs>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { fc, db } from "@/firebase/init";
import { mapGetters } from "vuex";
import moment from "moment";

import PagespeedInsights from "@/components/site_items_components/Pagespeed_insites";
//import Google_A from "@/components/site_items_components/Google_Analytics";
//import Search_console from "@/components/site_items_components/Search_console";
import SiteDashboard from "@/components/site_items_components/Site_Dashboard";
import updated from "@/components/global/lastUpdated";

export default {
  data() {
    return {
      site: [],
      tab: [],
      pagespeed: [],

      items: [
        {
          text: "Home",
          disabled: false,
          href: "/dashboard",
        },
        {
          text: "Sites",
          disabled: false,
          href: "/sites",
        },
        {
          text: this.$route.params.slug,
          disabled: true,
          href: "sites",
        },
      ],
    };
  },
  //name: "sitecurrent",
  created() {
    let ref = db
      .collection("Sites")
      .where("slug", "==", this.$route.params.slug);
    ref.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        console.log(doc.data());
        this.site = doc.data();
        this.site.id = doc.id;
        //  this.site.date = moment
        //    .unix(doc.data(0).date.seconds)
        //    .format("ddd D/M/Y  [at]  h:mm a");
        // this.site.ago = moment.unix(doc.data(0).date.seconds).fromNow();
      });
    });
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    SiteDashboard,
    PagespeedInsights,
    //Google_A,
    //Search_console,
    updated,
  },
  props: ["siteid", "sitetitle", "siteurl"],
};
</script>

<style>
.top_space {
  margin-top: 50px;
}

.GA {
  border: 1px solid yellow;
}
</style>
