<template>
  <div>
    <div>
      <zingchart ref="myChart" :data="chartConfig"> </zingchart>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { fc, db } from "@/firebase/init";
import moment from "moment";
import zingchartVue from "zingchart-vue";
export default {
  name: "GraphLighthousepagescore",
  props: ["siteurl", "siteid", "passeddata", "title", "sitetitle"],
  components: {
    zingchart: zingchartVue,
  },
  data() {
    return {};
  },
  computed: {
    chartConfig() {
      return {
        type: "line",
       // stacked: true,
        title: {
          text: "Pagescore",
          fontColor: "black",
        },
        subtitle: {
          text: "Lighthouse Pagescore",
          fontColor: "grey",
        },
        plot: {
          mode: "normal",
         // aspect: "spline",
          tooltip: {
         
              text: "%kl<br>%v /100",
          
            "border-radius": "5px"
          },
          animation: {
            delay: 500,
            effect: "ANIMATION_SLIDE_BOTTOM",
            speed: "1000",
            method: "2",
            sequence: "0",
          },
        },
        "scale-x": {
          zooming: true,
          // "zoom-to": [50, 100],
          step: "day",
          transform: {
            type: "date",
            all: "%d %M %y",
          },
        },
        "scale-y": {
          values: "0:100:20",
          markers: [
            {
              type: "area",
              range: [0, 50],
              "value-range": true,
              "background-color": "red",
              alpha: 0.2,
            },
            {
              type: "area",
              range: [50, 90],
              "value-range": true,
              "background-color": "orange",
              alpha: 0.2,
            },
            {
              type: "area",
              range: [90, 100],
              "value-range": true,
              "background-color": "green",
              alpha: 0.2,
            },
          ],
        },
        preview: {
          "background-color": "#F5F7F3",
          "preserve-zoom": false,
          mask: {
            backgroundColor: "white",
            alpha: 0.8,
          },
          handle: {
            "border-width": 1,
          },
          markers: [
            {
              type: "area",
              range: [0, 50],
              "value-range": true,
              "background-color": "red",
              alpha: 0.2,
            },
            {
              type: "area",
              range: [50, 90],
              "value-range": true,
              "background-color": "orange",
              alpha: 0.2,
            },
            {
              type: "area",
              range: [90, 100],
              "value-range": true,
              "background-color": "green",
              alpha: 0.2,
            },
          ],
        },
        legend: {},
        utc: true,
        timezone: 0,
        series: [
          {
            values: this.$props.passeddata.map((a) => [a.date, a.Pagescore]),
            color: "black",
            text: "Pagescore/100",
            "line-color": "black",
            
          },
        ],
        //finish
      };
    },
  },
};
</script>
<style></style>
