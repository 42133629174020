var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"updated"},[(!_vm.loading)?_c('div',{staticClass:"text-right"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-av-timer")]),_c('strong',[_vm._v(" last updated : "+_vm._s(this.siteData[0].ago)+" ")])],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"small text-right"},[_vm._v(" "+_vm._s(this.siteData[0].date)+" ")]):_vm._e(),_c('div',{staticClass:"small text-right test"},[_c('v-btn',{staticClass:"refresh_button",attrs:{"href":'https://us-central1-jpi-core-web-vitals.cloudfunctions.net/webvitals-combi?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl,"target":"_blank","x-small":"","loading":_vm.loading2,"disabled":_vm.loading2,"depressed":"","color":"normal"},on:{"click":function($event){_vm.loader = 'loading2'}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}])},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-refresh")]),_vm._v("refresh site ")],1),_c('v-btn',{staticClass:"refresh_button",attrs:{"href":'https://us-central1-jpi-core-web-vitals.cloudfunctions.net/webvitals-mobile?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl,"target":"_blank","x-small":"","loading":_vm.loading2,"disabled":_vm.loading2,"depressed":"","color":"normal"},on:{"click":function($event){_vm.loader = 'loading2'}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}])},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-refresh")]),_vm._v("mobile remote ")],1),_c('v-btn',{staticClass:"refresh_button",attrs:{"href":'http://localhost:5001/jpi-core-web-vitals/us-central1/webvitals-mobile?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl,"target":"_blank","x-small":"","loading":_vm.loading2,"disabled":_vm.loading2,"depressed":"","color":"normal"},on:{"click":function($event){_vm.loader = 'loading2'}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}])},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-refresh")]),_vm._v("mobile local ")],1),_c('v-btn',{staticClass:"refresh_button",attrs:{"href":'http://localhost:5001/jpi-core-web-vitals/us-central1/webvitals-combi?site_id=' +
            this.$props.siteid +
            '&site_url=' +
            this.$props.siteurl,"target":"_blank","x-small":"","loading":_vm.loading2,"disabled":_vm.loading2,"depressed":"","color":"normal"},on:{"click":function($event){_vm.loader = 'loading2'}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}])},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-refresh")]),_vm._v("Refresh Local ")],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }