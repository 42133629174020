<template>
  <div>
    <!--  Passed : {{ this.$props.passedData }}-->
    <zingchart
      :data="chartData"
      :series="series"
      height="300px"
      width="100%"
    ></zingchart>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { fc, db } from "@/firebase/init";
export default {
  name: "lighthouseScoreGraph",
  props: ["passedData"],
  data() {
    return {
      chartData: {
        type: "gauge",
        theme: "light",
        "background-color": "#EDF0F0",
        scale: { "size-factor": "100%" },
        "scale-r": {
          aperture: 180, //Specify your scale range.
          values: "0:100:10", //Provide min/max/step scale values.
          ring: {
            rules: [
              {
                rule: "%v >= 0 && %v <= 49",
                "background-color": "red",
              },
              {
                rule: "%v >= 50 && %v <= 89",
                "background-color": "orange",
              },
              {
                rule: "%v >= 90 && %v <= 100",
                "background-color": "green",
              },
            ],
          },
        },
      },
      series: [
        {
          values: [this.$props.passedData],
        },
      ],
    };
  },
};
</script>
<style></style>
