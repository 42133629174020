<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-breadcrumbs :items="items">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="ma-2 col-11"
            ><h1>Pingdom</h1>

            {{ info }}</v-col
          ></v-row
        >

        <v-divider></v-divider>
        <v-card>
          <v-row>
            <v-col class="ma-2 col-11">
              <pingdom></pingdom>
            </v-col>

            <!-- end date -->
          </v-row></v-card
        >
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import pingdom from "@/components/pingdom/pingdommain";

export default {
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "dashboard",
      },
      {
        text: "Pingdom",
        disabled: true,
        href: "admin",
      },
    ],
    info: null,
  }),
  computed: {},
  
  components: { pingdom },
};
</script>

<style>
.add_site {
  margin-top: 50px;
}
</style>
