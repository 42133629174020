<template>
  <div class="container">
    <h1>Login to webvitals</h1>

   
    <v-form action="#" @submit.prevent="submit">
      <v-container>
         <!--
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <v-text-field label="email" v-model="form.email"> </v-text-field>
            <v-text-field label="password" id="password" type="password" class="validate" name="password" required
              v-model="form.password">
            </v-text-field>
            <v-btn type="submit" elevation="2"  medium>Submit here</v-btn>
          </v-col>
        </v-row>

        -->
        <v-row>
          <v-col><p>
      or Sign In with Google <br>
      <button @click="socialLogin" class="social-button">
        <img alt="Google Logo" src="../assets/images/google.png">
      </button>
    </p></v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
  import firebase from "firebase";
  export default {
    data() {
      return {
        form: {
          email: "",
          password: "",
        },
        error: null,
      };
    },
    methods: {
      submit() {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.form.password)
          .then((data) => {
            this.$router.replace({
              name: "Dashboard",
            });
          })
          .catch((err) => {
            this.error = err.message;
          });
      },
    },
  };
</script>