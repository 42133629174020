<template>
  <v-container v-if="document[0]">
    <v-row class="greyback">
      <v-col md="12"
        ><h5>Lighthouse results for {{ document[0].title }}</h5></v-col
      >
      <v-col md="4">
        <v-card outlined :color="getColorlh_score(document[0].LH_score)">
          <lighthouseScoreGraph v-bind:passedData="document[0].LH_score">
          </lighthouseScoreGraph>
          <h4 class="mid">Pagescore - {{ document[0].LH_score }}</h4></v-card
        >
      </v-col>
      <v-col md="8">
        <v-row>
          <v-col md="4"
            ><v-card
              dense
              outlined
              :color="getColorLH_lcp(document[0].LH_lcp.slice(0, -2))"
              ><v-card-title><h5>LCP</h5></v-card-title>
              <v-card-subtitle
                ><h6>Largest Contentful Paint - 25%</h6></v-card-subtitle
              >
              <v-card-text
                ><h4>{{ document[0].LH_lcp }}</h4></v-card-text
              >
              <div class="rating">
                <v-chip x-small color="red">over 4s</v-chip>
                <v-chip x-small color="orange">2.5s - 4s</v-chip>
                <v-chip x-small color="green">under 2.5s</v-chip>
              </div></v-card
            ></v-col
          >
          <v-col md="4"
            ><v-card
              outlined
              :color="getColorLH_tbt(document[0].LH_tbt.slice(0, -3))"
              ><v-card-title><h5>TBT</h5></v-card-title>
              <v-card-subtitle
                ><h6>Total Blocking Time - 25%</h6></v-card-subtitle
              >
              <v-card-text
                ><h4>{{ document[0].LH_tbt }}</h4></v-card-text
              >
              <div class="rating">
                <v-chip x-small color="red">over 600ms</v-chip>
                <v-chip x-small color="orange">300 - 600ms</v-chip>
                <v-chip x-small color="green">under 400ms</v-chip>
              </div></v-card
            ></v-col
          >
          <v-col md="4"
            ><v-card
              outlined
              :color="getColorLH_fcp(document[0].LH_fcp.slice(0, -2))"
              ><v-card-title><h5>FCP</h5></v-card-title>
              <v-card-subtitle
                ><h6>First Contentful Paint - 15%</h6></v-card-subtitle
              >
              <v-card-text
                ><h4>{{ document[0].LH_fcp }}</h4></v-card-text
              >
              <div class="rating">
                <v-chip x-small color="red">over 4s</v-chip>
                <v-chip x-small color="orange">2s - 5 s</v-chip
                ><v-chip x-small color="green">under 2s</v-chip>
              </div></v-card
            ></v-col
          >
          <v-col md="4"
            ><v-card
              outlined
              :color="
                getColorLH_speedindex(document[0].LH_speedindex.slice(0, -2))
              "
              ><v-card-title><h5>Speed Index</h5></v-card-title>
              <v-card-subtitle><h6>Speed Index - 15%</h6></v-card-subtitle>
              <v-card-text
                ><h4>{{ document[0].LH_speedindex }}</h4></v-card-text
              >
              <div class="rating">
                <v-chip x-small color="red">over 5.8s</v-chip>
                <v-chip x-small color="orange">4.4s - 5.8s</v-chip
                ><v-chip x-small color="green">under 4.3s</v-chip>
              </div>
            </v-card></v-col
          >
          <v-col md="4"
            ><v-card
              outlined
              :color="
                getColorLH_timetointeractive(
                  document[0].LH_timetointeractive.slice(0, -2)
                )
              "
              ><v-card-title><h5>Time to Interactive</h5></v-card-title>
              <v-card-subtitle
                ><h6>Time to Interactive - 15%</h6></v-card-subtitle
              >
              <v-card-text
                ><h4>{{ document[0].LH_timetointeractive }}</h4></v-card-text
              >
              <div class="rating">
                <v-chip x-small color="red">over 7.3s</v-chip>
                <v-chip x-small color="orange">3.9s - 7.3s</v-chip
                ><v-chip x-small color="green">under 3.8s</v-chip>
              </div></v-card
            ></v-col
          >
          <v-col md="4"
            ><v-card outlined :color="getColorLH_cls(document[0].LH_cls)"
              ><v-card-title><h5>CLS</h5></v-card-title>
              <v-card-subtitle
                ><h6>Cumulative Layout Shift - 5%</h6></v-card-subtitle
              >
              <v-card-text
                ><h4>{{ document[0].LH_cls }}</h4></v-card-text
              >
              <div class="rating">
                <v-chip x-small color="red">over 0.25</v-chip>
                <v-chip x-small color="orange">0.1 - 0.25</v-chip
                ><v-chip x-small color="green">under 0.1</v-chip>
              </div></v-card
            ></v-col
          ></v-row
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import * as firebase from "firebase";
import moment from "moment";
//import db from "@/firebase/init";
import { fc, db } from "@/firebase/init";
import lighthouseScoreGraph from "@/components/site_items_components/graphs/lighthouse_scoreGraph";
export default {
  name: "dashboard_single_lighthouse_panel",
  props: ["siteid"],
  components: {
    lighthouseScoreGraph,
  },
  data() {
    return {
      document: [],
    };
  },
  mounted() {
    // fetch data from firestore
    db.collection("Sites")
      .doc(this.$props.siteid)
      .get()
      .then((snapshot) => {
        const document = snapshot.data();
        document.date = moment
          .unix(document.date.seconds)
          .format("ddd DD MMM YYYY [at] hh:ss a");
        document.ago = moment
          .unix(document.date.seconds)
          .format("ddd DD MMM YYYY [at] hh:ss a");
        return this.document.push(document);
      });
  },
  methods: {
    getColorlh_score(lh) {
      if (lh < 49) return "red";
      else if (lh < 89) return "orange";
      else return "green";
    },
    getColorLH_speedindex(lh) {
      if (lh < 4.4) return "green";
      else if (lh < 5.9) return "orange";
      else return "red";
    },
    getColorLH_timetointeractive(lh) {
      if (lh < 3.8) return "green";
      else if (lh < 7.3) return "orange";
      else return "red";
    },
    getColorLH_fcp(lh) {
      if (lh > 4) return "red";
      else if (lh > 2) return "orange";
      else return "green";
    },
    getColorLH_tbt(lh) {
      if (lh < 300) return "green";
      else if (lh < 600) return "orange";
      else return "red";
    },
    getColorLH_cls(lh) {
      if (lh < 0.1) return "green";
      else if (lh < 0.25) return "orange";
      else return "red";
    },
    getColorLH_lcp(lh) {
      if (lh < 2.5) return "green";
      else if (lh < 4.0) return "orange";
      else return "red";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
<style scoped>
.greyback {
  background: #edf0f0;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid lightgrey;
}
.mid {
  text-align: center;
}
.v-card h5,
.v-card h4 {
  color: white;
}
.rating {
  padding: 5px;
}
.rating .v-chip {
  width: 33%;
  border: 1px solid white !important;
  color: white;
  text-align: center;
  padding: 0px 0px 0px 5px !important;
  font-size: 10px !important;
}
</style>
