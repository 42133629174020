<template>
  <div>
    <v-select
      v-model="compare"
      :items="sites"
      label="Select Sites to Compare"
      multiple
      chips
      prepend-icon="mdi-earth"
      attach
    >
    </v-select>
  </div>
</template>
<script>
import {fc, db} from "@/firebase/init";
export default {
  name: "SiteSelect",
  data() {
    return {
      sites: [],
    };
  },
  created() {
    // fetch data from firestore
    db.collection("Sites")
      .orderBy("title")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());
          let site = doc.data();
          site.id = doc.id;
          this.sites.push(site.title);
        });
      });
  },
};
</script>
