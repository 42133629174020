<template>
  <div>
    <!--Passed : {{ this.$props.passedData}}-->
    <zingchart :data="chartData" :series="Series" height="75"></zingchart>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {fc, db} from "@/firebase/init";
export default {
  name: "dashboardGraph",
  props: ["passedData", "title"],
  data() {
    return {
      chartData: {
        type: "hbar",
        theme: "light",
        title: [this.$props.title],
        scaleX: {
          visible: false,
        },
        scaleY: {
          visible: false,
        },
        plotarea: {
          "adjust-layout": true,
        },
        plot: {
          stacked: "true",
          "stack-type": "normal",
          

          animation: {
            effect: "ANIMATION_EXPAND_BOTTOM",
            delay: "200",
          },
        },
        "scale-x": {
          labels: ["Poor", "Med", "Good"],
        },
      },
      Series: [
        { values: [this.$props.passedData[2]],"border-radius":"3px 0px 0px 3px" ,"background-color": "green", },
        { values: [this.$props.passedData[1]], "background-color": "orange" },
        { values: [this.$props.passedData[0]], "background-color": "red","border-radius":"0px 3px 3px 0px", },
      ],
    };
  },
};
</script>
<style></style>
