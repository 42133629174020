<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-breadcrumbs :items="items">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="ma-2 col-11"><h1>Admin</h1></v-col></v-row
        >

        <v-divider></v-divider>

        <v-row>
          <v-col class="ma-2 col-11">
            <v-card>
              <!-- Item to select-->

              <v-btn
                class="ma-2"
                
                v-bind:href="
                  'https://us-central1-jpi-core-web-vitals.cloudfunctions.net/wv-featured'
                "
                target="_blank"
              >
                Update all featured sites
              </v-btn>

              <v-btn class="ma-2"  @click="loader = 'loading'" v-bind:href="
                  'http://localhost:5001/jpi-core-web-vitals/us-central1/wv-featured'
                "
                target="_blank">
                Update all featured sites Local
              </v-btn>
            </v-card>
          </v-col>

          <!-- end date -->
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "dashboard",
      },
      {
        text: "Admin",
        disabled: true,
        href: "admin",
      },
    ],
  }),
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  components: {},
};
</script>

<style>
.add_site {
  margin-top: 50px;
}
</style>
