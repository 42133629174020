import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login'
import Register from '@/views/Register'
import Dashboard from '@/views/Dashboard'
import Landing from '@/views/Landing'
import Admin from '@/views/Admin'
import Pingdom from '@/views/Pingdom'
import Sites from '@/views/Sites'
import AddSite from '@/views/Site_Add'
import EditSite from '@/components/Edit_Site'
import Site_detail from '@/views/Site_Detail'
import Search_Console from '@/views/Search_Console'
import Pagespeed_Insights from '@/views/Pagespeed_Insights'
import Google_a from '@/views/Google_A'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'Landing',
        component: Landing
    },
    {
        path: '/sites',
        name: 'Sites',
        component: Sites,
        
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      
  },
  {
    path: '/pingdom',
    name: 'Pingdom',
    component: Pingdom,
    
},
    {
        path: '/add-site',
        name: 'AddSite',
        component: AddSite
      },
      {
        path: '/edit-site/:slug',
        name: 'EditSite',
        component: EditSite
      },
      {
        path: '/sites/:slug', 
        name: 'Site',
        component: Site_detail
      },
      {
        path: '/search-console', 
        name: 'Search_Console',
        component: Search_Console
      },
      {
        path: '/pagespeed-insights', 
        name: 'Pagespeed_insights',
        component: Pagespeed_Insights
      },
      {
        path: '/google-anylitics', 
        name: 'Google_anylitics',
        component: Google_a
      }
    
]
});

export default router