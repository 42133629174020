<template
  ><div>
    <!-- switch to go between desktop (default)and mobile -->
    <span class="float-right">
        <span class="float-right">
          <!--
    <v-switch
      style="margin-top:20px;display:inline-block"
      v-model="switch1"
      
      class="modeswitch"
    ></v-switch>
    <v-icon medium v v-if="!switch1" color="#03a9f4 " class="modeswitch" @change="change_desktop(desktop)"
      >mdi-desktop-classic</v-icon
    >
    <v-icon medium v-if="switch1" color="#8bc34a" class="modeswitch" @change="change_desktop(mobile)"
      >mdi-cellphone-text</v-icon
    >
    -->
  </span>

      <v-radio-group v-model="platform" >
       
        
        <v-radio
          name="platform"
          label="Mobile"
          value="mobile"
          color="white"
          @change="change_mobile('mobile')"
        >
          <template v-slot:label>
            <div class="white-text"> <v-icon size="25" color="#03a9f4 " class="modeswitch"
      >mdi-cellphone-text</v-icon
    ></div>
          </template></v-radio>

           <v-radio
          name="platform"
          label="Desktop"
          value="desktop"
          color="white"
          @change="change_desktop('desktop')"
        >
          <template v-slot:label>
            <div class="white-text"> <v-icon  size="25" color="#8bc34a" class="modeswitch" 
      >mdi-desktop-classic</v-icon
    ></div>
          </template></v-radio>
        
      </v-radio-group>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "GlobalSwitch",
      platform: "desktop",
            switch1: false,

    };
  },
  methods: {
    change_desktop: function() {
      this.$store.commit("changePlatform", "desktop");
    },
    change_mobile: function() {
      this.$store.commit("changePlatform", "mobile");
    },
  },
  watch: {},
};
</script>

<style>
.mode {
  display: inline;
}
.modeswitch {
  margin-top: -10px;
}
.modeswitch .v-input--selection-controls__input div {
 color: #8bc34a !important;
}
.v-input--is-label-active.modeswitch .v-input--selection-controls__input div {
  color: #03a9f4 !important;
}

.mobile {
  color: #03a9f4 !important;
}
.desktop {
  color: #8bc34a !important;
}

.white-text {
  color: white;
}
</style>
