// Vue > Component > Main Navigation Bar on top
<template >
 <!-- <div v-if="user.loggedIn" :class="this.$store.state.platform">-->
     <div v-if="user.loggedIn" >
    <v-system-bar dark  id="globalswitch">
      <div class="container">
        <GlobalSwitch></GlobalSwitch></div
    ></v-system-bar>

    <v-app-bar dark flat id="topmenu">
      <div class="container">
        <v-row class="menu">
          <a href="/dashboard"
            ><img src="../assets/images/web-vitals-logo.svg" class="small_logo"
          /></a>
          <router-link to="/sites">
            <v-btn color="grey darken-3" small class="header_button"
              ><v-icon small dark left>mdi-earth</v-icon>Sites</v-btn
            >
          </router-link>
          <router-link to="/pingdom">
            <v-btn color="grey darken-3" small class="header_button"
              ><v-icon small dark left>mdi-cloud-question</v-icon>Pingdom</v-btn
            >
          </router-link>
          <!--
            <router-link to="/pagespeed-insights">
              <v-btn color="grey darken-3" small class="header_button"
                ><v-icon small dark left>mdi-gauge</v-icon>Pagespeed
                Insights</v-btn
              >
            </router-link>

            <router-link to="/search-console">
              <v-btn color="grey darken-3" small class="header_button"
                ><v-icon small dark left>mdi-magnify</v-icon>Search
                Console</v-btn
              >
            </router-link>
            <router-link to="/google-anylitics">
              <v-btn color="grey darken-3" small class="header_button"
                ><v-icon small dark left>mdi-google-analytics</v-icon>Google
                Analytics</v-btn
              >
            </router-link>

            -->
          <v-spacer></v-spacer>
          <router-link to="/admin">
            <v-btn color="grey darken-3" small class="header_button"
              ><v-icon small dark left>mdi-cogs</v-icon></v-btn
            >
          </router-link>

          <a @click.prevent="signOut">
            <v-btn color="grey darken-3" small class="header_button"
              ><v-icon small dark left>mdi-account-box</v-icon> Log out
            </v-btn>
          </a>
        </v-row>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import GlobalSwitch from "@/components/global/GlobalSwitch";

export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  data: () => ({
    items: [],
  }),

  components: {
    GlobalSwitch,
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Landing",
          });
        });
    },
  },
};
</script>
<style scoped>
.small_logo {
  vertical-align: middle;
  margin-right: 50px;
  margin-left: 10px;
  height: 60px;
  padding: 5px;
}
.line.v-list-item {
  border-bottom: 1px solid #a6a6a6;
}
.header_button {
  margin-right: 10px;
  margin-top: 15px;
}

.selector {
  color: white !important;
}

.mobile .v-system-bar {
   background-color: #3D7EC1;
}
.desktop .v-system-bar {
 
   background-color: #95b93c;
}

#globalswitch {position: fixed;
    top: calc(50% - 75px);
    right: 0%;
    z-index: 999;
    width:75px;
    padding:0px;
    border-radius: 20px 0px 0px 20px;
    margin-right:20px;

 
    height: 90px!important;
    
    overflow: visible;
    background: rgba(0,0,0,1)
   
    }

    #topmenu  {
    top: 0px;
    left: 0;

    width: 100%;
    height: 23px;
    margin-bottom:20px}
</style>
