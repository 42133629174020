// Before you create app Chrome Dev
Vue.config.devtools = process.env.NODE_ENV === "development";
import Vue from "vue";
import App from "./App.vue";
import router from "./routes/index";
import * as firebase from "firebase";
import store from "./store";
import db from "@/firebase/init";
import Vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import { Tabs, Tab } from "vue-tabs-component";
import 'zingchart/es6';
import zingchartVue from 'zingchart-vue';
Vue.component('zingchart', zingchartVue)
Vue.component("tabs", Tabs);
Vue.component("tab", Tab);
Vue.prototype.$http = axios;
Vue.use(Vuetify);
Vue.use(require("vue-moment"));
Vue.config.productionTip = false;
firebase.auth().onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});



//added 29 jan for authentication
var firebaseui = require('firebaseui');
// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());
//added 29 jan for authentication

// Global filters
Vue.filter("uppercase", function(value) {
  if (!value) return "";
  return value.toString().toUpperCase();
});
Vue.filter("percentage", function(value, decimals) {
  if (!value) {
    value = 0;
  }
  if (!decimals) {
    decimals = 0;
  }
  value = value * 100;
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  value = value + "%";
  return value;
});
new Vue({
  router,
  store,
  vuetify: Vuetify,
  render: (h) => h(App),
}).$mount("#app");
