import firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/functions";
import "firebase/analytics";

  // Initialize Firebase
var firebaseConfig = {
    apiKey: "AIzaSyD3wZqrD-XD4O86QbZk8UcoL5elgAJk9g0",
    authDomain: "jpi-core-web-vitals.firebaseapp.com",
    databaseURL: "https://jpi-core-web-vitals.firebaseio.com",
    projectId: "jpi-core-web-vitals",
    storageBucket: "jpi-core-web-vitals.appspot.com",
    messagingSenderId: "67134165231",
    appId: "1:67134165231:web:452d98ee01c0d1a0cb0b1a",
    measurementId: "G-LMY83065J0"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const fc = firebase.functions();
  const db = firebase.firestore();
  firebase.analytics();

  
  export { fc, db};
  
  // export firestore database
  //export default firebaseApp.firestore()