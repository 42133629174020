<template>
  <div>
    <!-- Working front page featured  {{sites}} -->
    <v-data-table
      dense
      :headers="computedHeaders"
      :items="sites"
      :items-per-page="30"
      item-key="sites"
      :sort-by="['title']"
      :sort-desc="[false]"
    >
      <!-- featured column-->
      <template v-slot:item.featured="{ item }">
        <template v-if="item.featured === 'true'"
          ><v-icon medium color="orange darken-5">mdi-star</v-icon></template
        >
      </template>
      <!-- Title column-->
      <template v-slot:item.title="{ item }">
        <router-link :to="{ name: 'Site', params: { slug: item.slug } }">
          {{ item.title }}
        </router-link>
      </template>

      <!-- smiles -->

      <!-- page or site -->
        <template v-slot:item.Scannedpage="{ item }">
          <!--   <strong 
              >
             google = {{ item.Scannedpage}}</strong><br>
            Site - {{ item.website+'/'  }}-->
          <!-- page successful -->
          <div
            v-if="
              item.website + '/' == item.Scannedpage ||
                item.website == item.Scannedpage
            "
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="green" v-bind="attrs" v-on="on"
                  >mdi-emoticon</v-icon
                >
              </template>
              <span>
                <v-icon color="green">mdi-emoticon</v-icon>Getting Page Results - use both
                <br />Pagespeed = {{ item.Scannedpage }}<br />
                Site - {{ item.website + "/" }}</span
              >
            </v-tooltip>
          </div>

          <div v-else>
         
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="orange" v-bind="attrs" v-on="on"
                  >mdi-emoticon-frown</v-icon
                >
              </template>
              <span>
                <v-icon color="orange">mdi-emoticon-frown</v-icon>Getting Site Origins - use lighthouse only
                <br />Pagespeed = {{ item.Scannedpage }}<br />
                Site - {{ item.website + "/" }}</span
              >
            </v-tooltip>
          </div>
        </template>

      <!-- smiles -->

      <!-- lighthouse -->

      <!-- score -->
      <template v-slot:item.LH_score="{ item }">
        <v-chip :color="getColorlh_score(item.LH_score)" dark x-small>
          {{ item.LH_score }}
        </v-chip>
      </template>
      <!-- score -->

      <!-- Speed Index-->
      <template v-slot:item.LH_speedindex="{ item }">
        <v-chip :color="getColorLH_speedindex(item.LH_speedindex)" dark x-small>
          {{ item.LH_speedindex }}s
        </v-chip>
      </template>
      <!-- Speed Index -->

      <!-- Time to interactive-->
      <template v-slot:item.LH_timetointeractive="{ item }">
        <v-chip
          :color="getColorLH_timetointeractive(item.LH_timetointeractive)"
          dark
          x-small
        >
          {{ item.LH_timetointeractive }}s
        </v-chip>
      </template>
      <!-- Time to interactive--->

      <!-- FCP-->
      <template v-slot:item.LH_fcp="{ item }">
        <v-chip :color="getColorLH_fcp(LH_fcp)" dark x-small>
          {{ item.LH_fcp }}s
        </v-chip>
      </template>
      <!-- FCP--->

      <!-- TBT-->
      <template v-slot:item.LH_tbt="{ item }">
        <v-chip :color="getColorLH_tbt(item.LH_tbt)" dark x-small>
          {{ item.LH_tbt }}ms
        </v-chip>
      </template>
      <!-- TBT--->

      <!-- CLS-->
      <template v-slot:item.LH_cls="{ item }">
        <v-chip :color="getColorLH_cls(item.LH_cls)" dark x-small>
          {{ item.LH_cls }}
        </v-chip>
      </template>
      <!-- TBT--->

      <!-- LCP-->
      <template v-slot:item.LH_lcp="{ item }">
        <v-chip :color="getColorLH_lcp(item.LH_lcp)" dark x-small>
          {{ item.LH_lcp }}s  
        </v-chip>
      </template>
      <!-- LCP--->

      <!-- lighthouse end-->

      <!-- Pagespeed-->

      <template v-slot:item.PS_FCP_Percentile="{ item }">
        <v-chip x-small label :class="item.PS_FCP">
          {{ item.PS_FCP_Percentile }} s
        </v-chip>
      </template>

      <template v-slot:item.PS_FID_Percentile="{ item }">
        <v-chip label x-small :class="item.PS_FID">
          {{ item.PS_FID_Percentile }} ms
        </v-chip>
      </template>

      <template v-slot:item.PS_LCP_Percentile="{ item }">
        <v-chip label x-small :class="item.PS_LCP">
          {{ item.PS_LCP_Percentile }} s
        </v-chip>
      </template>
      <template v-slot:item.PS_CLS_Percentile="{ item }">
        <v-chip x-small label :class="item.PS_CLS">
          {{ item.PS_CLS_Percentile }}
        </v-chip>
      </template>

      <!-- PS FCP-->

      <!-- CRUD column-->

      <template v-slot:item.actions="{ item }">
        <!-- edit site -->
        <router-link
          :to="{ name: 'EditSite', params: { slug: item.slug } }"
          class="link"
        >
          <v-icon small class="mr-2 edit">
            mdi-pencil
          </v-icon>
        </router-link>
        <!-- edit site -->
      </template>

      <template v-slot:top>
        <v-app-bar flat dense color="white">
          <v-toolbar-title
            ><h5 v-if="!switch1">Page Speed Insites</h5>

            <h5 v-if="switch1">
              Lighthouse
            </h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-icon mediumv v-if="switch1" color="primary">mdi-lighthouse</v-icon>
          <v-icon small v-if="!switch1">mdi-lighthouse</v-icon>
          <v-switch style="margin-top:20px;" v-model="switch1"></v-switch>
        </v-app-bar>
      </template>
    </v-data-table>

    {{ item }}
  </div>
</template>
<script>

import {fc, db} from "@/firebase/init";
//import db from "@/firebase/init";
import moment from "moment";
export default {
  name: "sites",
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      sites: [],
      selected: ["pagespeed"],
      switch1: false, //lighthouse hide set to false
      switch2: false,
    };
  },

  computed: {
    computedHeaders() {
      let headers = [
        // { text: "Featured", value: "featured" },
        { text: "Title", value: "title" },
         { text: "Title", value: "title" },
        // { text: "Area", value: "unit" },
         { text: "Orgin", value: "Scannedpage" },
      ];

      if (!this.switch1) {
        headers.push({ text: "Largest Contentful Paint (LCP)", value: "PS_LCP_Percentile" }),
         headers.push({ text: "First Input Delay (FID)", value: "PS_FID_Percentile" }),
          headers.push({ text: "Cumulative Layout Shift (CLS)", value: "PS_CLS_Percentile" })
       // headers.push({ text: "FCP", value: "PS_FCP_Percentile" })
         
        
         
      }

      if (this.switch1) {
        headers.push({ text: "Score ", value: "LH_score" }),
          headers.push({ text: "LCP", value: "LH_lcp" ,class: "weight_25"}),
          headers.push({ text: "TBT", value: "LH_tbt",class: "weight_25" }),
          headers.push({ text: "FCP", value: "LH_fcp" ,class: "weight_15"}),
          headers.push({ text: "Speed Index ", value: "LH_speedindex",class: "weight_15" }),
          headers.push({
            text: "Time to Interactive",
            value: "LH_timetointeractive",class: "weight_15"
          }),
          headers.push({ text: "CLS", value: "LH_cls" ,class: "weight_5"});
      }

      return headers;
    },
  },
  methods: {
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },

    //

    getColorlh_score(lh) {
      if (lh < 49) return "red";
      else if (lh < 89) return "orange";
      else return "green";
    },

    // Speed index ok
    getColorLH_speedindex(lh) {
      if (lh < 4.4) return "green";
      else if (lh < 5.9) return "orange";
      else return "red";



    },

    getColorLH_timetointeractive(lh) {
  

      if (lh < 3.8) return "green";
      else if (lh < 7.3) return "orange";
      else return "red";
    },

    getColorLH_fcp(lh) {
      if (lh > 4) return "red";
      else if (lh > 2) return "orange";
      else return "green";
    },
    getColorLH_tbt(lh) {
      if (lh < 300) return "green";
      else if (lh < 600) return "orange";
      else return "red";




      
    },
    getColorLH_cls(lh) {
     

        if (lh < 0.1) return "green";
      else if (lh < 0.25) return "orange";
      else return "red";
    },
    getColorLH_lcp(lh) {
      if (lh < 2.5) return "green";
      else if (lh < 4.0) return "orange";
      else return "pink";
     

     
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    // fetch data from firestore
    db.collection("Sites")
      .where("featured", "==", "true")
      //.orderBy("title")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());
          let site = doc.data();
          site.id = doc.id;
          this.sites.push(site);
          site.date = moment.unix(site.date.seconds).format("D/M/YY");
          site.LH_speedindex = site.LH_speedindex.slice(0, -2);
          site.LH_fcp = site.LH_fcp.slice(0, -2);
          site.LH_timetointeractive = site.LH_timetointeractive.slice(0, -2);
          site.LH_lcp = site.LH_lcp.slice(0, -2);
          site.LH_tbt = site.LH_tbt.slice(0, -3).replace(/,/g, "");
        });
      });
  },
};
</script>

<style>
.AVERAGE {
  background: orange !important;
  color: white !important;
}
.SLOW {
  background: RED !important;
  color: white !important;
}
.FAST {
  background: #5ab55d !important;
  color: white !important;
}

.v-chip .v-chip__content {
  min-width: 30px !important;
  text-align: center !important;
}

.link:link {
  text-decoration: none;
}
.link:link .edit,
.link:visited .edit {
  color: rgba(0, 0, 0, 0.2);
}
.link:hover .edit {
  color: orange;
}
</style>
