<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-breadcrumbs :items="items">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>

        <h1>Pagespeed Insites </h1>

        <v-divider></v-divider>
        <v-card>
          <v-row>
            <v-col class="ma-2 col-11">
              <!-- Item to select-->

              <SiteSelect></SiteSelect>
            </v-col>
            <!-- Item to select-->
            <v-col class="ma-2 col-3"
              ><v-select
                :items="selectitems"
                label="Criteria to view"
                prepend-icon="mdi-file-document-outline"
              ></v-select
            ></v-col>

            <!-- start date -->




            <v-col class="ma-2 col-4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="true"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startdate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker v-model="startdate" no-title scrollable>
                 
                 
                </v-date-picker>
              </v-menu>
            </v-col>






            <!-- end date -->
            <v-col class="ma-2 col-4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="true"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="enddate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="enddate" no-title scrollable>
                 
                  
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row
            ><v-col
              ><line-chart :data="linechart"  height="500px" :curve="false" :colors="['#b00', '#666']"/></v-col
          ></v-row>

          <v-row
            ><v-col class="ma-2"
              ><v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="5"
                class="elevation-1"
              ></v-data-table></v-col
          ></v-row>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import SiteSelect from "@/components/Sites_select";
export default {
  data: () => ({
    date1: new Date().toISOString().substr(0, 10),
    selectitems: ["Performance Score", "Cumulative Layout Shift", "First Contentful Paint", "Time to Interactive", "Speed Index", "First Input Delay", "Largest Contentful Paint"],
    linechart: [
      {
        name: "Scotsman",
        data: {
          "2020-12-01 ": 32,
          "2020-12-02 ": 44,
          "2020-12-03 ": 95,
          "2020-12-04 ": 32,
          "2020-12-05 ": 105,
          "2020-12-06 ": 95,
          "2020-12-07 ": 22,
          "2020-12-08 ": 44,
          "2020-12-09 ": 95,
          "2020-12-10 ": 16,
          "2020-12-11 ": 84,
          "2020-12-12 ": 95,
        },
      },
      {
        name: "Yorkshire Post",
        data: {
          "2020-12-01 ": 19,
          "2020-12-02 ": 45,
          "2020-12-03 ": 45,
          "2020-12-04 ": 32,
          "2020-12-05 ": 44,
          "2020-12-06 ": 33,
          "2020-12-07 ": 76,
          "2020-12-08 ": 44,
          "2020-12-09 ": 55,
          "2020-12-10 ": 32,
          "2020-12-11 ": 16,
          "2020-12-12 ": 95,
        },
      },
      {
        name: "Edinburgh Evening News",
        data: {
          "2020-12-01 ": 199,
          "2020-12-02 ": 475,
          "2020-12-03 ": 475,
          "2020-12-04 ": 372,
          "2020-12-05 ": 474,
          "2020-12-06 ": 373,
          "2020-12-07 ": 776,
          "2020-12-08 ": 474,
          "2020-12-09 ": 575,
          "2020-12-10 ": 372,
          "2020-12-11 ": 176,
          "2020-12-12 ": 975,
        },
      },
    ],
    e6: [],
    e7: [],

    items: [
      {
        text: "Home",
        disabled: false,
        href: "dashboard",
      },
      {
        text: "Search Console",
        disabled: true,
        href: "search-console",
      },
    ],
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    SiteSelect,
  },
};
</script>

<style>
.add_site {
  margin-top: 50px;
}
</style>
