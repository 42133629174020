<template>
  <v-app>
    <v-form @submit.prevent="AddSite" v-model="valid">
      <v-row>
        <v-col>
          <v-checkbox
            v-model="checkbox"
            name="featured"
            label="Featured Site"
          ></v-checkbox>
          <!-- site title -->
          <v-text-field
            label="Site title:"
            name="title"
            v-model="title"
            placeholder="Daily Planet"
            required
            :rules="nameRules"
          >
          </v-text-field>
          <!-- site url-->
          <v-text-field
            label="Website Url:"
            hide-details="auto"
            name="website"
            v-model="website"
            placeholder="https://example.com"
            type="url"
            required
          >
          </v-text-field
        ></v-col>
      </v-row>
      <!-- Switch -->
      <cv-row>
        <v-switch v-model="switch1" label="internal/external"></v-switch>
      </cv-row>
      <v-row v-show="!switch1">
        <v-col>
          <strong> External Site</strong><br />
          Select site type.<br />
          <v-radio-group v-model="unit" column dense>
            <v-radio label="Test" value="Test" dense></v-radio>
            <v-radio label="Competitor" value="Competitor"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-show="switch1">
        <v-col>
          <strong> Internal JPI site</strong><br />
          Select JPI region.<br />
          <v-radio-group v-model="unit" column dense>
            <v-radio label="Ireland" value="Ireland" dense></v-radio>
            <v-radio label="Midlands" value="Midlands"></v-radio>
            <v-radio label="North East" value="North East"></v-radio>
            <v-radio label="North West" value="North West"></v-radio>
            <v-radio label="NMSY" value="NMSY"></v-radio>
            <v-radio label="Scotland" value="Scotland"></v-radio>
            <v-radio label="South" value="South"></v-radio>
            <v-radio label="Yorkshire" value="Yorkshire"></v-radio>
          </v-radio-group> </v-col
        ><!--
        <v-col>
          <v-text-field
            label="Address 1"
            name="street1"
            hide-details="auto"
          ></v-text-field
          ><v-text-field
            label="Address 2"
            name="street2"
            hide-details="auto"
          ></v-text-field
          ><v-text-field
            label="Town"
            name="town"
            hide-details="auto"
          ></v-text-field
          ><v-text-field
            label="Region"
            name="region"
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            label="Postcode"
            name="postcode"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-file-input
            label="Logo:"
            truncate-length="15"
            hide-details="auto"
            name="logo"
          ></v-file-input>
          <v-text-field
            label="Latatude"
            prepend-icon="mdi-map-marker"
            name="Lat"
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            label="Longatude"
            prepend-icon="mdi-map-marker"
            name="Long"
            hide-details="auto"
          ></v-text-field
        ></v-col>-->
      </v-row>
      <v-textarea
        filled
        name="add-details"
        label="Details"
        value="A brief site description"
        dense
      ></v-textarea>
      <v-btn color="primary" elevation="2" class="btn" @click="AddSite"
        ><v-icon dark left> mdi-earth </v-icon>Add new site
      </v-btn>
    </v-form>
  </v-app>
</template>
<script>
import { fc, db } from "@/firebase/init";
import slugify from "slugify";
export default {
  name: "Site_add_component",
  data() {
    return {
      title: "",
      website: null,
      unit: null,
      details: [],
      checked: false,
      checkbox: false,
      switch1: true,
      config: {},
      slug: null,
      internal: true,
      valid: false,
      nameRules: [(v) => !!v || "Name is required"],
    };
  },
  methods: {
    AddSite() {
      if (this.title) {
        this.feedback = null;
        //create slug
        this.slug = slugify(this.title, {
          replacement: "-",
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true,
        });
        console.log(this.slug);
        db.collection("Sites")
          .add({
            title: this.title,
            website: this.website,
            unit: this.unit,
            slug: this.slug,
            featured: this.checkbox.toString(),
          })
          .then(() => {
            this.$router.push({ name: "Sites" });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.feedback = "You must enter a Site title";
      }
    },
  },
};
</script>
<style>
.switch {
  margin-bottom: 30px;
}
.region {
  margin-left: 15px;
}
</style>
